import { MenuItem, Select } from "@mui/material"
import { KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { memo, useCallback } from "react"
import type { Control } from "react-hook-form"
import { useController } from "react-hook-form"

import type { AddProductModalType } from "./AddProductSchema"
import { useGetLocationAssociation } from "./Product/Information/hooks/useGetLocationAssociation"

import { FormField } from "@/components/Forms"
import type { ProductLocationAssociationInput } from "@/graphql/codegen/graphql"
import { formatLocationAssociationAddress } from "@/utils/utils"

interface ProductShipLocationSelectProps {
  name: "locationsAssociations"
  control: Control<AddProductModalType>
}

export type LocationAssociation = {
  locationAssociationId: string
  address1: string
  city: string
  regionIsoCode: string
}

const ProductShipLocationSelect: FunctionComponent<ProductShipLocationSelectProps> = ({ name, control }) => {
  const { field: companyIdField } = useController({
    name: "companyId",
    control,
  })

  const { locationsAssociationsList } = useGetLocationAssociation({
    companyId: companyIdField.value ?? "",
  })

  const shippingLocations: LocationAssociation[] = locationsAssociationsList
    .map(({ shipping, companyLocationAssociationId }) => ({
      locationAssociationId: companyLocationAssociationId,
      address1: shipping?.address1 ?? "",
      city: shipping?.city ?? "",
      regionIsoCode: shipping?.regionIsoCode ?? "",
    }))
    .filter(({ address1, city, regionIsoCode }) => Boolean(address1 || city || regionIsoCode))

  const options = shippingLocations.map((location) => ({
    value: location.locationAssociationId,
    label: formatLocationAssociationAddress(location),
  }))

  const handleSingleChange = useCallback(
    (event: any, field: any) => {
      const value = event.target.value
      const selectedLocation = shippingLocations.find((loc) => loc.locationAssociationId === value)
      if (selectedLocation) {
        field.onChange([{ locationAssociationId: selectedLocation.locationAssociationId }])
      } else {
        field.onChange([])
      }
    },
    [shippingLocations]
  )

  return (
    <FormField
      name={name}
      label='Product Ship From Location'
      render={({ field, fieldState: { error } }) => {
        if (!field.value) return null

        const selectedLocations = (field.value as ProductLocationAssociationInput[])
          .map((val) => val.locationAssociationId)
          .filter(Boolean)

        return (
          <Select
            {...field}
            displayEmpty
            error={!!error}
            value={selectedLocations[0] || ""}
            onChange={(e) => handleSingleChange(e, field)}
            SelectDisplayProps={{
              className:
                "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
            }}
            classes={{
              icon: "text-gray-600",
            }}
            IconComponent={KeyboardArrowDownOutlined}
          >
            <MenuItem value=''>
              <span className='text-gray-400'>Select One</span>
            </MenuItem>
            {options.map(({ value, label }) => (
              <MenuItem key={`option-${value}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        )
      }}
    />
  )
}

export default memo(ProductShipLocationSelect)
