import { useMemo } from "react"

import type { Location, Product } from "@/graphql/codegen/graphql.ts"
import type { ProductSelectionItem } from "@/screens/Procurement/Supplier/types/types.ts"

export const useMergedLocations = (
  productsOrProduct: Product | ProductSelectionItem[] | null,
  incotermLocations: Location[],
  onlyActiveLocations: boolean = false
): Location[] => {
  const products = useMemo(
    () => (Array.isArray(productsOrProduct) ? productsOrProduct : [productsOrProduct]),
    [productsOrProduct]
  )

  const companyLocationAssociations = useMemo(() => {
    return products.flatMap(
      (product) => product?.company?.locationsAssociations?.filter((location) => location.shipping) || []
    )
  }, [products])

  const companyShippingLocations = useMemo(
    () =>
      companyLocationAssociations
        .flatMap((locationAssociation) => locationAssociation.shipping)
        .filter(
          (location): location is Location =>
            Boolean(location?.locationId) && (!onlyActiveLocations || location?.isActive === true)
        ),
    [companyLocationAssociations, onlyActiveLocations]
  )

  return useMemo(() => {
    const locationSet = new Set<string>()
    const uniqueLocations = [...incotermLocations, ...companyShippingLocations].filter((location) => {
      if (!location?.locationId) return false
      const isDuplicate = locationSet.has(location.locationId)
      locationSet.add(location.locationId)
      return !isDuplicate
    })
    return uniqueLocations.sort((a, b) => a.name.localeCompare(b.name))
  }, [incotermLocations, companyShippingLocations])
}
