import { zodResolver } from "@hookform/resolvers/zod"
import { Alert, Box, Collapse, IconButton, Paper, Typography } from "@mui/material"
import {
  AddOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "urql"

import { useProductConditions } from "../hooks/useProductConditions"

import AddProductConditionsModal from "./AddProductConditionsModal"
import { AddProductConditionsSchema } from "./AddProductConditionsSchema"
import ProductConditionsTable from "./ProductConditionsTable"

import { Button } from "@/components/common/Button"
import { UpdateProductMutationDocument } from "@/graphql/codegen/graphql"
import type {
  Mutation,
  ProductMutationUpdateArgs,
  ProductStockType,
  ProductUpdateFailure,
} from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

export const ProductConditionsContainer = (): ReactElement => {
  const [expanded, setExpanded] = useState(true)
  const [addConditionsModal, setAddConditionsModal] = useState(false)
  const { enqueueNotification } = useNotificationsStore()
  const methods = useForm({
    defaultValues: {
      stockType: "",
      excludedFromCombinedPricing: false,
      isOnAllocation: false,
    },
    resolver: zodResolver(AddProductConditionsSchema),
    mode: "all",
  })
  const { productId: queryCompanyId } = useParams({
    from: "/product/$productId/edit",
  })

  const { data: productConditions, fetch: fetchProductConditions } = useProductConditions({
    productId: Number(queryCompanyId),
  })
  const [, updateProduct] = useMutation<Pick<Mutation, "product">, ProductMutationUpdateArgs>(
    UpdateProductMutationDocument
  )

  const { setError } = methods

  const showProductConditions =
    productConditions?.stockType || productConditions?.excludedFromCombinedPricing || productConditions?.isOnAllocation

  const handleSubmit = useCallback(
    async (data: {
      stockType: ProductStockType | null
      excludedFromCombinedPricing: boolean
      isOnAllocation: boolean
    }) => {
      const { error, data: result } = await updateProduct({
        input: {
          ...data,
          productId: Number(queryCompanyId),
        },
      })
      if (error || (result?.product.update as ProductUpdateFailure)?.error) {
        setError("root", {
          // Keep this as "name"
          type: "server",
          message: error?.message || (result?.product?.update as ProductUpdateFailure)?.error?.message,
        })

        enqueueNotification({
          type: "error",
          silent: false,
          content: <p>Failed to {showProductConditions ? "update" : "add"} the product conditions</p>,
        })
      } else {
        enqueueNotification({
          type: "success",
          silent: false,
          content: <p>Product conditions successfully {showProductConditions ? "updated" : "added"}.</p>,
        })
      }
      methods.reset()
      setAddConditionsModal(false)
      fetchProductConditions({ productId: Number(queryCompanyId) })
    },
    [enqueueNotification, methods, queryCompanyId, setError, showProductConditions, updateProduct]
  )

  const onEditHandleClick = useCallback(() => {
    methods.reset({
      stockType: productConditions?.stockType ?? null,
      excludedFromCombinedPricing: productConditions?.excludedFromCombinedPricing ?? false,
      isOnAllocation: productConditions?.isOnAllocation ?? false,
    })
    setAddConditionsModal(true)
  }, [
    methods,
    productConditions?.excludedFromCombinedPricing,
    productConditions?.isOnAllocation,
    productConditions?.stockType,
  ])

  const onAddHandleClick = useCallback(() => {
    methods.reset({
      stockType: null,
      excludedFromCombinedPricing: false,
      isOnAllocation: false,
    })
    setAddConditionsModal(true)
  }, [methods])

  return (
    <Paper className='border border-gray-300 p-6' elevation={0}>
      <Box className='flex items-center justify-between'>
        <Typography variant='h6' className={expanded ? "mb-6" : ""}>
          Product Conditions
        </Typography>
        <IconButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label='show more'>
          {expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        {showProductConditions ? (
          <ProductConditionsTable productDetails={productConditions} onEditConditionClick={onEditHandleClick} />
        ) : (
          <Alert
            icon={<WarningOutlined color='primary' className='p-0' />}
            severity='info'
            variant='filled'
            classes={{
              message: "w-full flex justify-between p-0 mt-1",
            }}
            className='px-6 py-4'
          >
            <Typography variant='subtitle1' className='text-lg text-gray-900'>
              Define Product Condition Details
            </Typography>
            <Box className='flex items-center'>
              <Button size='small' appearance='outlined' onClick={onAddHandleClick}>
                <AddOutlined />
                Conditions
              </Button>
            </Box>
          </Alert>
        )}
      </Collapse>
      <FormProvider {...methods}>
        <AddProductConditionsModal
          open={addConditionsModal}
          onClose={() => {
            methods.reset()
            setAddConditionsModal(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
    </Paper>
  )
}
