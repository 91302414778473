import { z } from "zod"

export const AddProductInformationSchema = z.object({
  companyId: z.string().optional(),
  vendorSku: z.string().max(30, { message: "Vendor SKU must be less than 30 characters" }).optional(),
  vendorDescription: z
    .string()
    .min(1, { message: "Vendor Description is required" })
    .max(30, { message: "Vendor Description must be less than 30 characters" }),
  companyProductDescription: z
    .string()
    .min(1, { message: "Akrochem Description is required" })
    .max(30, { message: "Akrochem Description must be less than 30 characters" }),
  productManager: z.object({
    id: z.string().min(1, { message: "Select product manager" }),
    name: z.string().min(1, { message: "Select product manager" }),
  }),
  locationsAssociations: z
    .array(
      z.object({
        address1: z.string().optional(),
        city: z.string().optional(),
        regionIsoCode: z.string().optional(),
        locationAssociationId: z.string().optional(),
      })
    )
    .optional()
    .default([]),
})

// Schema for step two validation
export const AddProductSpecificsSchema = z
  .object({
    classification: z.object({
      id: z.string().min(1, { message: "Select product classification" }),
      name: z.string().min(1, { message: "Select product classification" }),
    }),
    productFormInfo: z.string().min(1, { message: "Product Form is required" }),
    uomId: z.string().min(1, { message: "Unit of Measurement is required" }),
  })
  .refine((data) => data.productFormInfo && data.uomId, {
    message: "Both Product Form and Unit of Measurement must be selected",
    path: ["productFormInfo", "uomId"],
  })

// Combined schema that makes step two fields optional
export const AddProductModalSchema = AddProductInformationSchema.extend({
  classification: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional(),
  productFormInfo: z.string(),
  uomId: z.string(),
})

// UI-only schema extension for form state management
export const AddProductModalUISchema = AddProductModalSchema.extend({
  _hasExistingLocations: z.boolean().optional(),
}).superRefine((data, ctx) => {
  // If this is an edit (has existing locations) and trying to clear them
  if (data._hasExistingLocations && (!data.locationsAssociations || data.locationsAssociations.length === 0)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Cannot remove all locations once they have been set. You can only update them to different locations.",
      path: ["locationsAssociations"],
    })
  }
})

export type AddProductModalType = z.infer<typeof AddProductModalSchema>
export type AddProductModalUIType = z.infer<typeof AddProductModalUISchema>
