import { Box, IconButton, Paper, Typography } from "@mui/material"
import { AddOutlined, EditOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { type ReactElement } from "react"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import { ImagePreviewGrid } from "@/components/upload/ImagePreviewGrid"
import type { ProductFormInfoItem, UnitOfMeasurement } from "@/graphql/codegen/graphql"

interface ProductFormGridProps {
  formInfo: ProductFormInfoItem | null
  uomId: string
  imageUrls: string[]
  units: UnitOfMeasurement[]
  onEditDetails?: () => void
  onViewPictures?: () => void
}

export const ProductFormGrid = ({
  formInfo,
  uomId,
  imageUrls,
  units,
  onEditDetails,
  onViewPictures,
}: ProductFormGridProps): ReactElement => {
  const unit = units.find((u) => u.id === uomId)
  const hasInformation = formInfo || uomId

  return (
    <Box className='grid grid-cols-1 divide-y divide-gray-200 border border-gray-200' data-testid='product-form-grid'>
      <Paper className='border border-gray-300 p-6' elevation={0}>
        <Typography variant='h6' className='mb-6'>
          Product Form Information
        </Typography>
        {!hasInformation ? (
          <InformationMissingAlert
            icon={<WarningOutlined color='primary' />}
            title='Add Product Form Information'
            action={
              <Button
                color='inherit'
                size='small'
                appearance='outlined'
                className='text-primary-500'
                startIcon={<AddOutlined />}
                onClick={onEditDetails}
                data-testid='add-product-info-button'
              >
                Product Form Info
              </Button>
            }
            description={""}
            data-testid='product-info-alert'
          />
        ) : (
          <>
            {/* First Row - Header */}
            <Box className='flex items-center justify-between bg-gray-300 p-4' data-testid='form-info-header'>
              <Typography variant='subtitle1' className='font-medium text-gray-700'>
                Product Form Details
              </Typography>
              <IconButton
                color='primary'
                size='small'
                onClick={onEditDetails}
                className='hover:bg-gray-400/20'
                aria-label='Edit product form details'
              >
                <EditOutlined />
              </IconButton>
            </Box>

            {/* Second Row - Column Headers */}
            <Box className='grid grid-cols-3'>
              <Box className='flex items-center p-4'>
                <Typography variant='subtitle1' className='font-medium text-gray-700'>
                  Form
                </Typography>
              </Box>
              <Box className='flex items-center p-4'>
                <Typography variant='subtitle1' className='font-medium text-gray-700'>
                  UoM
                </Typography>
              </Box>
              <Box className='flex items-center p-4'>
                <Typography variant='subtitle1' className='font-medium text-gray-700'>
                  Product Pictures
                </Typography>
              </Box>
            </Box>

            {/* Third Row - Values */}
            <Box className='grid grid-cols-3'>
              <Box className='flex items-center p-4' data-testid='form-info-value'>
                <Typography variant='body2'>{formInfo || "-"}</Typography>
              </Box>
              <Box className='flex items-center p-4' data-testid='uom-value'>
                <Typography variant='body2'>{unit?.name || "-"}</Typography>
              </Box>
              <Box className='flex items-center gap-x-4 p-4'>
                {imageUrls.length > 0 ? (
                  <ImagePreviewGrid urls={imageUrls} onImageClick={onViewPictures} aria-label='Product images grid' />
                ) : (
                  <Typography variant='body2'>No Pictures</Typography>
                )}
              </Box>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  )
}
