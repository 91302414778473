import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback } from "react"
import { useFormContext } from "react-hook-form"

import type { AddProductModalType } from "./AddProductSchema"
import ProductShipLocationSelect from "./ProductShipLocationSelect"

import { Button } from "@/components/common/Button"

type AddLocationModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: AddProductModalType) => Promise<void>
}

const AddLocationModal: FunctionComponent<AddLocationModalProps> = ({ open, onClose, onSubmit }) => {
  const { reset, handleSubmit, control } = useFormContext<AddProductModalType>()

  const handleResetClick = useCallback(() => {
    reset()
    onClose("cancel")
  }, [reset, onClose])

  const handleOnSubmit = useCallback(
    async (formData: AddProductModalType) => {
      await onSubmit(formData)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        reset()
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10 font-normal text-primary'>Add Location</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => {
          reset()
          onClose("cancel")
        }}
        className='absolute right-4 top-4 text-primary'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <ProductShipLocationSelect name='locationsAssociations' control={control} />
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(handleOnSubmit)}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddLocationModal
