import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { EditOutlined } from "@mui-symbols-material/w300"
import type { ColumnDef } from "@tanstack/react-table"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import type { FunctionComponent } from "react"
import { memo, useMemo } from "react"

import { LeadTimeUnits } from "@/graphql/codegen/graphql"
import type { Product } from "@/graphql/codegen/graphql"
import { formatLeadTimeDisplay } from "@/screens/Products/utils"

type ProductDetails = Pick<Product, "weightPerTruckload" | "leadTimeInDays" | "minOrderQuantity"> & {
  leadTimeDisplayUnits?: LeadTimeUnits
}

type ShippingDetailsTableProps = {
  productDetails: ProductDetails
  onEditConditionClick: (productDetails: Product) => void
}

type ColumnContentProps = {
  content: string
}

const ColumnContent: FunctionComponent<ColumnContentProps> = (props: ColumnContentProps) => {
  const { content } = props

  return (
    <Typography variant='body2' className='mt-0.5 text-gray-700'>
      {content}
    </Typography>
  )
}

const ShippingDetailsTable: FunctionComponent<ShippingDetailsTableProps> = (props: ShippingDetailsTableProps) => {
  const { productDetails, onEditConditionClick } = props
  const columns = useMemo<ColumnDef<ProductDetails>[]>(
    () => [
      {
        accessorKey: "weightPerTruckload",
        header: "Pounds (lb) per Truckload",
        cell: ({ getValue, row }) => (
          <ColumnContent
            content={
              row.original.weightPerTruckload ? `${getValue<string>() ?? row.original.weightPerTruckload} lb` : "-"
            }
          />
        ),
      },
      {
        accessorKey: "leadTimeInDays",
        header: "Lead Time",
        cell: () => {
          const displayUom = productDetails.leadTimeDisplayUnits || LeadTimeUnits.Days
          return <ColumnContent content={formatLeadTimeDisplay(productDetails.leadTimeInDays, displayUom)} />
        },
      },
      {
        accessorKey: "minOrderQuantity",
        header: "Min Order Qty (lb)",
        cell: ({ getValue, row }) => (
          <ColumnContent
            content={row.original.minOrderQuantity ? `${getValue<string>() ?? row.original.minOrderQuantity} lb` : "-"}
          />
        ),
      },
      {
        accessorKey: "actions",
        header: "",
        cell: ({ row }) => (
          <IconButton
            color='primary'
            aria-label='edit product shipping details'
            onClick={() => onEditConditionClick(row.original as unknown as Product)}
          >
            <EditOutlined />
          </IconButton>
        ),
        size: 100,
        enableSorting: false,
      },
    ],
    [onEditConditionClick, productDetails]
  )

  const table = useReactTable({
    data: [productDetails],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <TableContainer component={Paper} className='mt-2 shadow-none'>
      <Table>
        <TableHead className='h-10 border-b border-gray-500'>
          <TableRow>
            {table.getHeaderGroups().map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <TableCell key={header.id} className='h-[64px] px-2 text-white' data-testid={header.id} padding='none'>
                  <Typography variant='subtitle2' className='text-gray-900'>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Typography>
                </TableCell>
              ))
            )}
          </TableRow>
        </TableHead>
        <TableBody data-testid='tableBody' className='table-row-group'>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  data-testid={`${row.id}-${cell.id}`}
                  padding='none'
                  className='h-[64px] px-2 text-gray-700 first:px-3'
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default memo(ShippingDetailsTable)
