import { useCallback, useEffect, useMemo, useState } from "react"
import type { CombinedError, OperationContext, UseMutationExecute } from "urql"
import { useMutation, useQuery } from "urql"

import type { Location } from "@/graphql/codegen/graphql"
import type {
  Company,
  CompanyListSuccess,
  CompanyLocationAssociationListSuccess,
  CompanyLocationAssociationMutationCreateArgs,
  CompanyLocationAssociationMutationUpdateArgs,
  CompanyLocationAssociationOutput,
  LocationListSuccess,
  LocationQueryListArgs,
  Mutation,
  Query,
} from "@/graphql/codegen/graphql.ts"
import {
  CompanyListBasicDocument,
  CompanyLocationAssociationListDocument,
  CreateNewCompanyLocationAssociationMutationDocument,
  EditCompanyLocationAssociationMutationDocument,
  LocationListQueryDocument,
} from "@/graphql/codegen/graphql.ts"
import useDebounce from "@/utils/useDebounce.tsx"

const LIMIT = 20

export const useGetAssociatedLocations = ({
  companyId,
}: {
  companyId: string
}): {
  loadMoreLocations: () => void
  loadMoreCompanies: () => void
  resetLists: () => void
  reexecuteQueryWithReset: () => void
  reexecuteCompanyNameQuery: (opts?: Partial<OperationContext>) => void
  locations: Location[]
  fetching: boolean
  error: CombinedError | undefined
  companyNameList: Company[]
  companyNameListFetching: boolean
  createCompanyLocationAssociation:
    | UseMutationExecute<Pick<Mutation, "companyLocationAssociation">, CompanyLocationAssociationMutationCreateArgs>
    | undefined
  updateCompanyLocationAssociation:
    | UseMutationExecute<Pick<Mutation, "companyLocationAssociation">, CompanyLocationAssociationMutationUpdateArgs>
    | undefined
  associatedLocationList: CompanyLocationAssociationOutput[]
  associatedLocationListFetching: boolean
  reexecuteAssociatedLocationQuery: (opts?: Partial<OperationContext>) => void
  setSearchTerm: (searchTerm: string) => void
} => {
  const [locationOffset, setLocationOffset] = useState(0)
  const [companyOffset, setCompanyOffset] = useState(0)
  const [locationList, setLocationList] = useState<Location[]>([])
  const [companyNameList, setCompanyNameList] = useState<Company[]>([])

  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const [
    { data: companyLocationsData, fetching: companyLocationsFetching, error: companyLocationsError },
    reexecuteCompanyLocationsQuery,
  ] = useQuery<Pick<Query, "location">, LocationQueryListArgs>({
    query: LocationListQueryDocument,
    variables: {
      input: { filter: { companyId }, limit: LIMIT, offset: locationOffset },
    },
  })

  const [{ data: companyNameListData, fetching: companyNameListFetching }, reexecuteCompanyNameQuery] = useQuery<
    Pick<Query, "company">
  >({
    query: CompanyListBasicDocument,
    variables: {
      filter: { types: ["TRANSPORTATION"], query: debouncedSearchTerm },
      limit: LIMIT,
      offset: companyOffset,
    },
  })

  const loadMoreLocations = useCallback(() => {
    setLocationOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  const loadMoreCompanies = useCallback(() => {
    setCompanyOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  const resetLists = useCallback(() => {
    setLocationOffset(0)
    setCompanyOffset(0)
    setLocationList([])
    setCompanyNameList([])
  }, [])

  useEffect(() => {
    setCompanyOffset(0)
    setCompanyNameList([])
  }, [debouncedSearchTerm])

  const [
    { data: associatedLocationListData, fetching: associatedLocationListFetching },
    reexecuteAssociatedLocationQuery,
  ] = useQuery<Pick<Query, "companyLocationAssociation">>({
    query: CompanyLocationAssociationListDocument,
    variables: { input: { filter: { companyId }, limit: LIMIT, offset: 0 } },
  })

  const associatedLocationList = useMemo(() => {
    return (
      (associatedLocationListData?.companyLocationAssociation.list as CompanyLocationAssociationListSuccess)
        ?.companyLocationAssociations || []
    )
  }, [associatedLocationListData])

  const [, createCompanyLocationAssociation] = useMutation<
    Pick<Mutation, "companyLocationAssociation">,
    CompanyLocationAssociationMutationCreateArgs
  >(CreateNewCompanyLocationAssociationMutationDocument)

  const [, updateCompanyLocationAssociation] = useMutation<
    Pick<Mutation, "companyLocationAssociation">,
    CompanyLocationAssociationMutationUpdateArgs
  >(EditCompanyLocationAssociationMutationDocument)

  useEffect(() => {
    if (companyNameListData) {
      const companyList = (companyNameListData?.company.list as CompanyListSuccess)?.companies || []
      setCompanyNameList((prev) => {
        const combined = companyOffset === 0 ? companyList : [...prev, ...companyList]
        const companySet = new Set()
        return combined.filter((company) => {
          if (companySet.has(company.companyId)) {
            return false
          }
          companySet.add(company.companyId)
          return true
        })
      })
    }
  }, [companyNameListData, companyOffset])

  useEffect(() => {
    if (companyLocationsData) {
      const locations = (companyLocationsData.location.list as LocationListSuccess).locations
      setLocationList((prev) => {
        const combined = locationOffset === 0 ? locations : [...prev, ...locations]
        const locationsSet = new Set()
        return combined.filter((loc) => {
          if (locationsSet.has(loc.locationId)) {
            return false
          }
          locationsSet.add(loc.locationId)
          return true
        })
      })
    }
  }, [companyLocationsData, locationOffset])

  const reexecuteQueryWithReset = useCallback(() => {
    reexecuteCompanyLocationsQuery({ requestPolicy: "network-only" })
  }, [reexecuteCompanyLocationsQuery])

  const reexecuteAssociatedLocationQueryWithReset = useCallback(() => {
    reexecuteAssociatedLocationQuery({ requestPolicy: "network-only" })
  }, [reexecuteAssociatedLocationQuery])

  return {
    locations: locationList,
    fetching: companyLocationsFetching,
    error: companyLocationsError,
    companyNameList,
    companyNameListFetching,
    reexecuteCompanyNameQuery,
    reexecuteQueryWithReset,
    createCompanyLocationAssociation,
    associatedLocationList,
    associatedLocationListFetching,
    reexecuteAssociatedLocationQuery: reexecuteAssociatedLocationQueryWithReset,
    loadMoreLocations,
    loadMoreCompanies,
    resetLists,
    updateCompanyLocationAssociation,
    setSearchTerm,
  }
}
