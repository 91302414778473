import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
} from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import React, { useCallback, useMemo } from "react"
import { useFormContext } from "react-hook-form"

import { Button } from "@/components/common/Button"
import type {
  Company,
  CompanyLocationAssociation,
  CompanyLocationAssociationOutput,
  Location,
} from "@/graphql/codegen/graphql"
import FormAutocomplete from "@/screens/Companies/components/FormAutocomplete.tsx"
import LocationSelect from "@/screens/Companies/create/components/LocationSelect.tsx"
import { useLocationOptions } from "@/screens/Companies/hooks/useLocationOptions.tsx"

type EditAssociatedLocationModalProps = {
  open: boolean
  locations: Location[]
  companyNameList: Company[]
  handleScrollLocations: (event: React.UIEvent<HTMLUListElement>) => void
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: CompanyLocationAssociationOutput) => void
  currentAssociation: CompanyLocationAssociation | undefined
  companyId: string
  setSearchTerm: (value: string) => void
  loadMoreCompanies: () => void
}

const EditAssociatedLocations: FunctionComponent<EditAssociatedLocationModalProps> = ({
  open,
  locations,
  companyNameList,
  handleScrollLocations,
  onClose,
  onSubmit,
  setSearchTerm,
  currentAssociation,
  loadMoreCompanies,
}) => {
  const shipViaOptions = useMemo(() => {
    return currentAssociation?.shippingVia ? companyNameList.concat(currentAssociation.shippingVia) : companyNameList
  }, [companyNameList, currentAssociation?.shippingVia])

  const { handleSubmit, formState, control, reset } = useFormContext<{
    shipping: string
    billing: string
    order: string
    office: string
    shippingVia: string
  }>()
  const { isDirty: isFieldTouched, errors } = formState

  const handleFormSubmit = useCallback(
    (value: CompanyLocationAssociationOutput) => {
      onSubmit(value)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  const handleResetClick = useCallback(() => {
    reset()
    onClose("cancel")
  }, [onClose, reset])

  const genericOptionList = useLocationOptions(locations)

  return (
    <>
      {open && (
        <Dialog open={open} onClose={(_, reason) => onClose(reason)} fullWidth>
          <DialogTitle className='pl-10 font-normal text-primary'>Edit Association of Locations</DialogTitle>
          <IconButton
            aria-label='close'
            onClick={() => onClose("cancel")}
            className='absolute right-4 top-4 text-primary'
          >
            <CloseOutlined />
          </IconButton>
          <Divider />
          <DialogContent classes={{ root: "px-10 space-y-4" }}>
            <LocationSelect
              control={control}
              name='shipping'
              label='Shipping'
              renderLabel='address1'
              required
              options={genericOptionList}
              handleScroll={handleScrollLocations}
              locations={locations}
              errorMessage={errors?.shipping?.message}
            />
            <LocationSelect
              control={control}
              name='billing'
              label='Billing'
              required
              disabled
              handleScroll={handleScrollLocations}
              options={genericOptionList}
              locations={locations}
              errorMessage={errors.billing?.message}
              showTooltip
              tooltipText='Updating this address will update the billing addresses for other associations'
            />
            <LocationSelect
              control={control}
              name='order'
              label='Order'
              required
              handleScroll={handleScrollLocations}
              options={genericOptionList}
              locations={locations}
              errorMessage={errors.order?.message}
            />

            <LocationSelect
              control={control}
              name='office'
              label='Office'
              hasClearButton
              handleScroll={handleScrollLocations}
              options={genericOptionList}
              locations={locations}
            />
            <FormAutocomplete
              control={control}
              name='shipVia'
              label='Ship Via'
              options={shipViaOptions}
              valueKey='companyId'
              labelKey='name'
              setSearchTerm={setSearchTerm}
              onLoadMore={loadMoreCompanies}
              renderOption={(props, option) => (
                <MenuItem {...props} divider key={`${option.label}-${option.externalSystemId}`}>
                  <ListItemText primary={option.name} secondary={option.externalSystemId} />
                </MenuItem>
              )}
            />
          </DialogContent>
          <DialogActions className={"justify-between px-10 pb-10"}>
            <Button appearance='outlined' onClick={handleResetClick}>
              Cancel
            </Button>
            <Button
              appearance='contained'
              type='submit'
              disabled={!isFieldTouched}
              onClick={handleSubmit(handleFormSubmit)}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default EditAssociatedLocations
