import { Box, FormControl, MenuItem, Select, TextField } from "@mui/material"
import { HelpOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import type { ControllerRenderProps, FieldError } from "react-hook-form"

import { FormField } from "../Forms"

interface TextFieldWithSelectProps {
  name: string
  label?: string
  selectName: string
  selectOptions: Record<string, string>
  placeholder?: string
  required?: boolean
  className?: string
  slotProps?: {
    label?: {
      className?: string
    }
    error?: {
      className?: string
    }
  }
  shouldHideError?: boolean
}

interface TextFieldWithSelectContentProps {
  field: ControllerRenderProps
  fieldState: { error?: FieldError }
  selectName: string
  selectOptions: Record<string, string>
  placeholder?: string
  shouldHideError?: boolean
}

const TextFieldWithSelectContent = ({
  field,
  fieldState: { error },
  selectName,
  selectOptions,
  placeholder = "00",
  shouldHideError = false,
}: TextFieldWithSelectContentProps) => (
  <TextField
    {...field}
    type='number'
    value={field.value ?? ""}
    onChange={(e) => {
      const value = e.target.value
      // Only convert to number if we have a value
      field.onChange(value === "" ? null : Number(value))
    }}
    slotProps={{
      input: {
        endAdornment: (
          <Box className='flex items-center gap-4'>
            <HelpOutlined />
            <FormField
              name={selectName}
              shouldHideError={shouldHideError}
              render={({ field: selectField }) => (
                <EndAdornmentSelect selectField={selectField}>
                  {Object.entries(selectOptions).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </EndAdornmentSelect>
              )}
            />
          </Box>
        ),
        classes: {
          input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
        },
        inputProps: {
          className: "hide-stepper",
        },
      },
    }}
    placeholder={placeholder}
    fullWidth
    variant='outlined'
    error={!!error}
  />
)

export const TextFieldWithSelect = ({
  name,
  label,
  selectName,
  selectOptions,
  placeholder = "00",
  required = false,
  slotProps,
  shouldHideError = false,
}: TextFieldWithSelectProps): React.ReactElement => {
  return (
    <Box className='flex grow flex-col gap-1'>
      <FormField
        name={name}
        label={label}
        required={required}
        slotProps={slotProps}
        render={(props) => (
          <TextFieldWithSelectContent
            {...props}
            selectName={selectName}
            selectOptions={selectOptions}
            placeholder={placeholder}
            shouldHideError={shouldHideError}
          />
        )}
      />
    </Box>
  )
}
export const EndAdornmentSelect: FC<{ selectField: any; children: React.ReactNode }> = ({ selectField, children }) => {
  return (
    <FormControl variant='outlined' size='small'>
      <Select
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          minWidth: "60px",
          "& .MuiSelect-select": {
            padding: "2px 4px",
          },
        }}
        {...selectField}
        displayEmpty
        IconComponent={KeyboardArrowDownOutlined}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 48 * 4.5 + 8, width: 60 },
          },
        }}
        inputProps={{ "aria-label": "Without label" }}
      >
        {children}
      </Select>
    </FormControl>
  )
}
