import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {
  AddCircleOutlined,
  DescriptionOutlined,
  MoreVertOutlined,
  PauseOutlined,
  SettingsOutlined,
} from "@mui-symbols-material/w300"
import { useSearch } from "@tanstack/react-router"
import type { Row } from "@tanstack/react-table"
import type { ReactNode } from "react"
import { useCallback, useRef, useState } from "react"

import { ComputedProductStatus, type Product } from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider.tsx"
import { useProductActionsStore } from "@/screens/Companies/products/stores/useProductsActions"
import { useProductSearchStore } from "@/screens/Companies/products/stores/useProductsSearchStore.tsx"
import { ProductActions, ProductActionsDialogs } from "@/screens/Companies/products/types/schema"

export const ProductActionMenu = ({ original: product }: Row<Product>): ReactNode => {
  const anchorElRef = useRef<HTMLButtonElement>(null)
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)
  const onAction = useProductActionsStore((state) => state.onAction)
  const setProductAtAction = useProductActionsStore((state) => state.setProductAtAction)
  const executeSearch = useProductSearchStore(({ executeSearch }) => executeSearch)
  const currentSearch = useSearch({ from: "/companies/$companyId/edit" })

  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
    setProductAtAction(product)
  }, [product, setProductAtAction])
  const handleClose = useCallback(() => {
    setOpen(false)
    setProductAtAction(null)
  }, [setProductAtAction])

  const handleDeactivate = async () => {
    await onAction(ProductActions.DEACTIVATE_PRODUCT)
    await executeSearch(queryClient, currentSearch, true)
    handleClose()
  }

  const handleActivate = async () => {
    await onAction(ProductActions.ACTIVATE_PRODUCT)
    await executeSearch(queryClient, currentSearch, true)
    handleClose()
  }

  return (
    <Box className='flex items-center justify-center'>
      <IconButton onClick={handleOpen} ref={anchorElRef}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorElRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => setOpenedDialog(ProductActionsDialogs.EDIT_PRODUCT_INFORMATION)}>
          <ListItemIcon>
            <DescriptionOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
          </ListItemIcon>
          <ListItemText primary='Edit Product Information' />
        </MenuItem>
        <MenuItem onClick={() => setOpenedDialog(ProductActionsDialogs.EDIT_PRODUCT_SPECIFICS)}>
          <ListItemIcon>
            <SettingsOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
          </ListItemIcon>
          <ListItemText primary='Edit Product Specifics' />
        </MenuItem>
        {product.computedProductStatus === ComputedProductStatus.Active ? (
          <MenuItem onClick={handleDeactivate}>
            <ListItemIcon>
              <PauseOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
            </ListItemIcon>
            <ListItemText primary='Deactivate Product' />
          </MenuItem>
        ) : (
          <MenuItem onClick={handleActivate}>
            <ListItemIcon>
              <AddCircleOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
            </ListItemIcon>
            <ListItemText primary='Activate' />
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
