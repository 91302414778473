import dayjs from "dayjs"
import type { StoreApi } from "zustand"
import { createStore } from "zustand"

import type {
  Comment,
  FreightTerms,
  Order,
  OrderLine,
  OrderLineInput,
  OrderUpdateInput,
  Product,
  ShipStatus,
  ShippingLeg,
} from "@/graphql/codegen/graphql"
import type { ClassifiedPricingPackages } from "@/screens/Procurement/PurchaseOrder/types"
import { PurchaseOrderSection } from "@/screens/Procurement/PurchaseOrder/types"

type HeaderOrderFields = Pick<
  Order,
  | "orderId"
  | "status"
  | "shipStatus"
  | "createdByUser"
  | "sourceLocationAssociationId"
  | "destinationLocationAssociationId"
  | "requestedDeliveryDate"
  | "sentDate"
  | "createdOn"
  | "expectedDeliveryDate"
  | "orderLines"
>

export type DeliveryTermsFields = {
  deliveryTerms: string
  deliveryLocationId: string
  freightTerms: FreightTerms | string
  shipViaCompanyId: string
  shipStatus: ShipStatus | string
}

/**
 * FormValues interface defines the structure of form values for each section of the purchase order.
 * Each section corresponds to a specific part of the purchase order form.
 * TODO: Define specific types for each section instead of using 'any'.
 */
interface FormValues {
  [PurchaseOrderSection.HEADER]: HeaderOrderFields
  [PurchaseOrderSection.DELIVERY_TERMS]: DeliveryTermsFields
  [PurchaseOrderSection.SHIPPING_LEGS]: ShippingLeg[]
  [PurchaseOrderSection.NOTES]: Order["note"]
  [PurchaseOrderSection.COMMENTS]: { currentComment: Comment; comments: Comment[] }
  [PurchaseOrderSection.ORDER_LINES]: OrderLineInput[]
  [PurchaseOrderSection.ORDER_LINES_DETAILS]: Record<number, ClassifiedPricingPackages>
  [PurchaseOrderSection.SUGGESTED_PRODUCTS]: Product[]
}

/**
 * PurchaseOrderState interface defines the state and actions for the purchase order store.
 * It includes the current purchase order, form values, modified fields, and actions to manipulate the state.
 */
export interface PurchaseOrderState {
  currentPO: Order | null
  formValues: FormValues
  modifiedFields: {
    [K in PurchaseOrderSection]?: Partial<OrderUpdateInput>
  }
  isInitialLoad: boolean

  // Actions
  setCurrentPO: (po: Partial<Order>) => void
  updateSectionFields: <K extends PurchaseOrderSection>(section: K, values: FormValues[K]) => void
  updateModifiedFields: (section: PurchaseOrderSection, fields: Partial<OrderUpdateInput>) => void
  resetSection: (section: PurchaseOrderSection) => void
  resetAllModifiedFields: () => void
  resetStore: () => void
  isManualOverridePriceMap: Record<string, boolean>
  setManualOverridePrice: (productId: string, isManualOverridePrice: boolean) => void
}

/**
 * initialFormValues object initializes the form values for each section of the purchase order.
 * This serves as the default state when no user input has been provided.
 */
const initialFormValues: FormValues = {
  [PurchaseOrderSection.HEADER]: {
    orderId: 0,
    createdByUser: {
      userId: "",
      details: {
        name: "",
      },
    },
    sourceLocationAssociationId: "",
    destinationLocationAssociationId: "",
    requestedDeliveryDate: "",
    sentDate: "",
    createdOn: "",
    expectedDeliveryDate: "",
  },
  [PurchaseOrderSection.DELIVERY_TERMS]: {
    deliveryTerms: "",
    deliveryLocationId: "",
    freightTerms: "" as FreightTerms,
    shipViaCompanyId: "",
    shipStatus: "" as ShipStatus,
  },
  [PurchaseOrderSection.SHIPPING_LEGS]: [],
  [PurchaseOrderSection.NOTES]: "",
  [PurchaseOrderSection.COMMENTS]: {
    currentComment: {},
    comments: [],
  },
  [PurchaseOrderSection.ORDER_LINES]: [],
  [PurchaseOrderSection.SUGGESTED_PRODUCTS]: [],
  [PurchaseOrderSection.ORDER_LINES_DETAILS]: {},
}

/**
 * usePurchaseOrderStore is a Zustand store that manages the state of the purchase order.
 * It provides actions to update the state and is wrapped with the devtools middleware for debugging.
 *
 * @returns A hook that can be used to access and modify the purchase order state.
 */
export const createPurchaseOrderStore = (): StoreApi<PurchaseOrderState> =>
  createStore<PurchaseOrderState>((set) => ({
    currentPO: null,
    formValues: initialFormValues,
    modifiedFields: {},
    isManualOverridePriceMap: {},
    isInitialLoad: true,

    /**
     * setCurrentPO updates the current purchase order in the store.
     * @param po - The new purchase order object to set.
     */
    setCurrentPO: (po) => set({ currentPO: po as Order }),

    /**
     * updateSectionFields updates the form values for a specific section of the purchase order.
     * @param section - The section of the purchase order to update.
     * @param values - The new values for the specified section.
     */
    updateSectionFields: (section, values) =>
      set((state) => {
        const newState = {
          formValues: {
            ...state.formValues,
            [section]: values,
          },
        }

        // Only update expected delivery date when not in initial load
        if (section === PurchaseOrderSection.ORDER_LINES && !state.isInitialLoad) {
          const orderLines = values as OrderLine[]
          const requestedDeliveryDate = state.formValues[PurchaseOrderSection.HEADER].requestedDeliveryDate

          // Calculate new requested delivery date even if it was initially null
          const longestLeadTime = orderLines?.reduce((max, line) => {
            const leadTime = line.productDetail?.product?.leadTimeInDays ?? 0
            return Math.max(max, leadTime)
          }, 0)

          // If requestedDeliveryDate is not available on PO, default to today plus longest lead time plus one day
          const newRequestedDeliveryDate = requestedDeliveryDate
            ? dayjs(requestedDeliveryDate).toISOString()
            : dayjs()
                .add(longestLeadTime ?? 0, "days")
                .add(1, "day")
                .toISOString()

          newState.formValues[PurchaseOrderSection.HEADER] = {
            ...newState.formValues[PurchaseOrderSection.HEADER],
            requestedDeliveryDate: newRequestedDeliveryDate,
            expectedDeliveryDate: null, // Set ETA to null (blank)
          }
        }

        return { ...newState, isInitialLoad: false }
      }),

    /**
     * updateModifiedFields updates the modified fields for a specific section of the purchase order.
     * This is used to track changes that need to be sent to the backend.
     * @param section - The section of the purchase order that was modified.
     * @param fields - The modified fields and their new values.
     */
    updateModifiedFields: (section, fields) =>
      set((state) => ({
        modifiedFields: {
          ...state.modifiedFields,
          [section]: {
            ...state.modifiedFields[section],
            ...fields,
          },
        },
      })),

    /**
     * resetSection resets a specific section of the purchase order to its initial state.
     * This is useful when discarding changes or initializing a section.
     * @param section - The section of the purchase order to reset.
     */
    resetSection: (section) =>
      set((state) => ({
        formValues: {
          ...state.formValues,
          [section]: initialFormValues[section],
        },
        modifiedFields: {
          ...state.modifiedFields,
          [section]: {},
        },
      })),

    /**
     * resetAllModifiedFields clears all modified fields across all sections.
     * This is typically used after successfully saving changes to the backend.
     */
    resetAllModifiedFields: () => set({ modifiedFields: {} }),

    /**
     * resetStore resets the entire store state to initial values.
     * Should be called when navigating away from a PO or unmounting the PO view.
     */
    resetStore: () =>
      set({
        currentPO: null,
        formValues: initialFormValues,
        modifiedFields: {},
        isManualOverridePriceMap: {},
        isInitialLoad: true,
      }),
    setManualOverridePrice: (productId, isManualOverridePrice) =>
      set((state) => ({
        isManualOverridePriceMap: {
          ...state.isManualOverridePriceMap,
          [productId]: isManualOverridePrice,
        },
      })),
  }))

export type { FormValues }
