import { Autocomplete, Box, TextField } from "@mui/material"
import { KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"

type GenericOption = {
  [key: string]: any
}

type FormAutocompleteProps = {
  control: any
  name: string
  label?: string
  options: GenericOption[]
  valueKey: string
  labelKey: string
  onLoadMore?: () => void
  setSearchTerm: (value: string) => void
  renderOption?: (props: any, option: GenericOption, state: any) => React.ReactNode
}

const scrollThreshold = 10

const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
  control,
  name,
  label,
  options,
  valueKey,
  labelKey,
  onLoadMore,
  setSearchTerm,
  renderOption,
}) => {
  const [localInputValue, setLocalInputValue] = useState("")
  const [isInitialValue, setIsInitialValue] = useState(true)

  // Reset searchTerm and localInputValue on unmount
  useEffect(() => {
    return () => {
      setSearchTerm("")
      setLocalInputValue("")
    }
  }, [setSearchTerm])

  return (
    <Box className='w-full'>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const currentValue = options.find((item) => item[valueKey] === field.value) || null

          const handleChange = (_: unknown, newValue: GenericOption | null) => {
            const newVal = newValue ? newValue[valueKey] : ""
            field.onChange(newVal)
            setLocalInputValue(newValue?.[labelKey] || "")
          }

          const handleInputChange = (_: React.SyntheticEvent<Element, Event>, newValue: string, reason: string) => {
            // If reset is triggered to re-align with the current value setLocalInputValue accordingly
            const isResetFromCurrent = reason === "reset" && currentValue?.[labelKey] === newValue && isInitialValue

            if (isResetFromCurrent) {
              setLocalInputValue(newValue)
            } else if (reason !== "selectOption" && reason !== "reset") {
              setLocalInputValue(newValue)
              setSearchTerm(newValue)
              setIsInitialValue(false)
            }
          }

          return (
            <>
              {label && <label className='mb-2 block text-sm font-normal text-gray-700'>{label}</label>}
              <Autocomplete
                value={currentValue}
                onChange={handleChange}
                inputValue={localInputValue}
                onInputChange={handleInputChange}
                options={options}
                getOptionLabel={(option) => option[labelKey] ?? ""}
                popupIcon={<KeyboardArrowDownOutlined />}
                renderOption={renderOption}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select One'
                    size='small'
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: 42,
                        border: "none",
                      },
                      "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
                        boxShadow: "none",
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  onScroll: (event) => {
                    const el = event.currentTarget
                    if (onLoadMore && el.scrollTop + el.clientHeight >= el.scrollHeight - scrollThreshold) {
                      onLoadMore()
                    }
                  },
                  style: {
                    maxHeight: 300,
                  },
                }}
              />
            </>
          )
        }}
      />
    </Box>
  )
}

export default FormAutocomplete
