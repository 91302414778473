import { Alert, AlertTitle, Box, LinearProgress, Typography } from "@mui/material"
import { AddOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { ExpandedState, OnChangeFn, RowSelectionState, SortingState } from "@tanstack/react-table"
import React, { useCallback, useEffect, useRef } from "react"
import type { UseFormReturn } from "react-hook-form"
import { FormProvider } from "react-hook-form"
import type { Client } from "urql"

import { getRefinedSearchParams } from "../utils"

import { accessorKeyToProductSortField } from "./constants"
import { useEditProduct } from "./hooks/useEditProduct"
import { useProductTable } from "./hooks/useProductTable"
import { ProductPricingTerms } from "./PricingPackage/ProductPricingTerms"
import type { ProductSearchFilters } from "./stores/useProductsSearchStore"
import { useProductSearchStore } from "./stores/useProductsSearchStore"
import type { ProductSortableAccessorKey } from "./utils/sorftFieldMap"

import { Button } from "@/components/common/Button"
import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import type { Company, Product } from "@/graphql/codegen/graphql"
import { CompanyStatus, SortingOrder } from "@/graphql/codegen/graphql"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"
import { useThrottle } from "@/hooks/useThrottle"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { useProductActionsStore } from "@/screens/Companies/products/stores/useProductsActions.tsx"
import AddProductModal from "@/screens/Products/components/AddProductModal"
import type { AddProductModalType } from "@/screens/Products/components/AddProductSchema"
import EditProductModalContainer from "@/screens/Products/components/EditProductModalContainer.tsx"
import { ProductStoreProvider } from "@/stores/useProductStore"
import useDebounce from "@/utils/useDebounce"

interface ProductListTableProps {
  company: Company
  methods: UseFormReturn<ProductSearchFilters>
  open: boolean
  data: Product[]
  fetchingProducts: boolean
  error: Error | undefined
  executeSearch: (client: Client, filters: ProductSearchFilters, skipFilterCheck?: boolean) => Promise<void>
  loadMore: (client: Client, filters: ProductSearchFilters) => Promise<void>
  setOpen: (open: boolean) => void
  onProductSelection: (products: Product[]) => void
}

export const ProductListTable: React.FC<ProductListTableProps> = ({
  company,
  methods,
  open,
  data,
  fetchingProducts,
  error,
  executeSearch,
  loadMore,
  setOpen,
  onProductSelection,
}) => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/companies/$companyId/edit" })
  const { category, query, field, order, ...restSearchParams } = currentSearch

  const setCompanyId = useProductSearchStore(({ setCompanyId }) => setCompanyId)

  useEffect(() => {
    setCompanyId(company.companyId)
  }, [company.companyId, setCompanyId])

  const { columns, expanded, selected, sorting, setExpanded, setSelected } = useProductTable({
    sortBy: field,
    sortOrder: order,
  })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const debouncedExecuteSearch = useCallback(
    useDebounce((filters: ProductSearchFilters) => {
      executeSearch?.(queryClient, filters)
    }, 300),
    [executeSearch, queryClient]
  )

  const handleSortingChange = useCallback(
    (updaterFn: (state: SortingState) => SortingState) => {
      const newSorting = updaterFn(sorting)
      if (newSorting.length > 0) {
        const sort = newSorting[0]
        const key = sort.id as ProductSortableAccessorKey
        const field = accessorKeyToProductSortField.get(key) //todo
        const params = {
          ...restSearchParams,
          query,
          category,
          field,
          order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
        }
        const refinedParams = getRefinedSearchParams(params)
        void navigate({ search: refinedParams.search, to: "/companies/", replace: true })
      }
    },
    [sorting, restSearchParams, query, category, navigate]
  )

  const handleRowSelectionChange = useCallback(
    (updaterOrValue: ((old: RowSelectionState) => RowSelectionState) | RowSelectionState) => {
      const newSelection = typeof updaterOrValue === "function" ? updaterOrValue(selected) : updaterOrValue
      setSelected(newSelection)

      const selectedProducts = Object.keys(newSelection)
        .filter((key) => newSelection[key])
        .map((productId) => data.find((product) => product.productId.toString() === productId))
        .filter((product): product is NonNullable<typeof product> => Boolean(product))

      onProductSelection(selectedProducts)
    },
    [selected, setSelected, data, onProductSelection]
  )

  const [throttledLoadMore] = useThrottle(
    () => loadMore(queryClient, { ...currentSearch, companyId: company.companyId }),
    200
  )
  const onScroll = useScrollLoadMore({
    fetching: fetchingProducts,
    loadMore: throttledLoadMore,
    scrollContainerRef: tableContainerRef,
  })

  const clearStore = useProductSearchStore(({ clearStore }) => clearStore)

  const productAtAction = useProductActionsStore((state) => state.productAtAction)
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)

  const {
    methods: productEditMethods,
    handleSubmit: productEditSubmit,
    resetDone: productEditResetDone,
  } = useEditProduct({ productId: Number(productAtAction?.productId) })

  const handleFormSubmit = async (data: AddProductModalType) => {
    await productEditSubmit(data)
    await executeSearch?.(queryClient, currentSearch, true)
    setOpenedDialog(null)
  }

  useEffect(() => {
    return () => {
      clearStore()
    }
  }, [company.companyId, clearStore])

  useEffect(() => {
    debouncedExecuteSearch(currentSearch)
  }, [debouncedExecuteSearch, currentSearch])

  if (!fetchingProducts && data.length === 0 && Object.keys(currentSearch).every((key) => !currentSearch[key])) {
    return (
      <Box my={2}>
        <Alert
          icon={<WarningOutlined color='primary' />}
          severity='info'
          variant='filled'
          classes={{
            message: "w-full flex flex-row justify-between",
          }}
        >
          <Box>
            <AlertTitle className='font-normal leading-7'>Add company products and pricing by location</AlertTitle>
            <Box className='w-full font-thin'>Products and pricing need to be added for each ship from location</Box>
          </Box>
          <Box className='flex items-center'>
            <Button size='small' appearance='outlined' onClick={() => setOpen(true)}>
              <AddOutlined />
              Add Product
            </Button>
          </Box>
        </Alert>
        <FormProvider {...methods}>
          <AddProductModal
            open={open}
            onClose={() => {
              setOpen(false)
            }}
            onSubmit={methods.handleSubmit}
          />
        </FormProvider>
      </Box>
    )
  }

  return (
    <Box className='flex h-full flex-col'>
      {fetchingProducts && <LinearProgress className='sticky top-0 z-10' />}
      <AerosBaseTable
        features={{
          table: {
            stickyHeader: true,
          },
          tanstackOptions: {
            data,
            columns,
            state: {
              expanded,
              rowSelection: selected,
              sorting,
            },
            enableRowSelection: company.status === CompanyStatus.Active,
            enableMultiRowSelection: true,
            enableExpanding: true,
            getRowCanExpand: () => true,
            getRowId: (row) => row.productId.toString(),
            onRowSelectionChange: handleRowSelectionChange,
            onExpandedChange: setExpanded as OnChangeFn<ExpandedState>,
            onSortingChange: handleSortingChange as OnChangeFn<SortingState>,
            renderExpandedContent: (row) => (
              <ProductStoreProvider>
                <ProductPricingTerms
                  productId={row.original.productId}
                  companyId={row.original.companyId}
                  sourceId={row.original.locationsAssociations?.[0]?.locationAssociationId ?? ""}
                  expanded={expanded[row.id]}
                />
              </ProductStoreProvider>
            ),
          },
        }}
        slotProps={{
          bodyCellExpanded: { className: "bg-primary-100" },
          container: {
            ref: tableContainerRef,
            onScroll,
          },
        }}
        noDataMessage='No products added'
      />
      {error && (
        <Typography color='error' className='p-4'>
          {error.message}
        </Typography>
      )}
      {productAtAction?.productId && (
        <FormProvider {...productEditMethods}>
          <EditProductModalContainer
            methods={productEditMethods}
            resetDone={productEditResetDone}
            onSubmit={handleFormSubmit}
          />
        </FormProvider>
      )}
    </Box>
  )
}
