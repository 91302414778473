import { createFileRoute, retainSearchParams, useNavigate, useSearch } from "@tanstack/react-router"
import { fallback } from "@tanstack/zod-adapter"
import { useEffect, useState } from "react"
import { z } from "zod"

import { SectionContainer } from "@/components/SectionContainer/SectionContainer"
import { OrderListFilterSchema, OrderStatus, SortingOrder } from "@/graphql/codegen/graphql"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"
import { PurchaseOrderFilterDrawer } from "@/screens/Procurement/PurchaseOrders/components/filters/PurchaseOrderFilterDrawer"
import { PurchaseOrderSearchFilterChips } from "@/screens/Procurement/PurchaseOrders/components/filters/PurchaseOrderSearchFilterChips"
import { PurchaseOrdersSearchBar } from "@/screens/Procurement/PurchaseOrders/components/PurchaseOrdersSearchBar"
import { PurchaseOrdersTable } from "@/screens/Procurement/PurchaseOrders/components/PurchaseOrdersTable"
import { PurchaseProductOrdersTable } from "@/screens/Procurement/PurchaseOrders/components/PurchaseProductOrdersTable"
import { PurchaseOrderSearchCategory } from "@/screens/Procurement/types/enums"

export const routePath = "/procurement/_layout/purchase-orders"
const DEFAULT_STATUSES = [OrderStatus.Confirmed, OrderStatus.Draft, OrderStatus.Sent]

const SearchParamsValidator = z.object({
  ...OrderListFilterSchema().shape,
  category: fallback(z.nativeEnum(PurchaseOrderSearchCategory), PurchaseOrderSearchCategory.SUPPLIER),
  order: fallback(z.nativeEnum(SortingOrder), SortingOrder.Desc),
  query: fallback(z.string().optional().default(""), ""),
})

export const Route = createFileRoute(routePath)({
  validateSearch: SearchParamsValidator,
  search: {
    middlewares: [retainSearchParams(["category", "query"])],
  },
  component: PurchaseOrdersComponent,
})

function PurchaseOrdersComponent() {
  const [showFilter, setShowFilter] = useState(false)
  const navigate = useNavigate({ from: routePath })
  const currentSearch = useSearch({ from: routePath })

  useEffect(() => {
    const params = getRefinedSearchParams(currentSearch, {
      ...currentSearch,
      statuses: DEFAULT_STATUSES,
    })
    void navigate(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* Filter Drawer */}
      <PurchaseOrderFilterDrawer
        routePath={routePath}
        showFilterDrawer={showFilter}
        toggleDrawer={() => setShowFilter(!showFilter)}
      />

      <SectionContainer className='flex flex-col gap-4 overflow-hidden' paddingVariant='none'>
        {/* Search Bar */}
        <PurchaseOrdersSearchBar onShowFilter={() => setShowFilter(!showFilter)} />
        {/* Filter Chips */}
        <PurchaseOrderSearchFilterChips />
        {/* Table */}
        {currentSearch.category === PurchaseOrderSearchCategory.SUPPLIER ? (
          <PurchaseOrdersTable />
        ) : (
          <PurchaseProductOrdersTable />
        )}
      </SectionContainer>
    </>
  )
}
