import { Box, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material"
import { HelpOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { ChangeEvent, ReactElement } from "react"
import { useEffect, useRef, useState } from "react"
import { type Control, Controller, type ControllerRenderProps } from "react-hook-form"

import { POUND_UOM_ID } from "@/constants/unitOfMeasurements.ts"
import type { OrderLineInput, UnitOfMeasurement } from "@/graphql/codegen/graphql.ts"

interface QuantityFieldProps {
  field: ControllerRenderProps<OrderLineInput, "quantityInUom">
  onInputChange: (args: { value: string; name: string }) => void
  filteredUoM: UnitOfMeasurement[]
  control: Control<OrderLineInput, any>
  convertUomValue: (value: number) => string
}

export const QuantityField = ({
  field,
  onInputChange,
  filteredUoM,
  control,
  convertUomValue,
}: QuantityFieldProps): ReactElement => {
  const [inputValue, setInputValue] = useState<string | number>(field.value)
  const isUserInputRef = useRef(false)

  useEffect(() => {
    const value = field.value
    const formattedValue = Number.isInteger(value) ? value.toString() : value.toFixed(2)
    setInputValue(isUserInputRef.current && !value ? "" : parseFloat(formattedValue))
  }, [field.value])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    isUserInputRef.current = true
    const numericValue = parseFloat(value)
    if (!Number.isNaN(numericValue) && numericValue < 0) {
      value = String(Math.abs(numericValue))
    }

    setInputValue(value ? numericValue : "")
    onInputChange({ value, name: "quantityInUom" })
  }

  const handleOnBlur = () => {
    if (!inputValue) {
      setInputValue(0)
    } else {
      const numericValue = Number(inputValue)
      if (!Number.isNaN(numericValue) && numericValue < 0) {
        setInputValue(Math.abs(numericValue))
      }
    }
    isUserInputRef.current = false
  }

  return (
    <Box className='relative w-[200px]'>
      <label className='mb-2 block text-sm font-thin text-gray-700'>Quantity</label>
      <TextField
        {...field}
        fullWidth
        variant='outlined'
        type='number'
        slotProps={{
          input: {
            endAdornment: (
              <Box className='align-end flex gap-1'>
                <HelpOutlined />
                <Controller
                  name={`uomId`}
                  control={control}
                  render={({ field }) => (
                    <FormControl variant='outlined' size='small'>
                      <Select
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          minWidth: "60px",
                          "& .MuiSelect-select": {
                            padding: "2px 4px",
                          },
                        }}
                        {...field}
                        displayEmpty
                        IconComponent={KeyboardArrowDownOutlined}
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: 48 * 4.5 + 8, width: 60 },
                          },
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => onInputChange({ value: e.target.value, name: "uomId" })}
                        value={filteredUoM.some(({ id }) => id === field.value) ? field.value : POUND_UOM_ID}
                      >
                        {filteredUoM.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
            ),
            classes: {
              input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
            },
            inputProps: {
              className: "hide-stepper",
            },
            onKeyDown: (e) => {
              if (e.key === "-" || e.key.toLowerCase() === "e") {
                e.preventDefault()
              }
            },
          },
        }}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleOnBlur}
        InputProps={{
          classes: {
            input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
          },
          inputProps: {
            className: "hide-stepper",
          },
        }}
      />
      {/* If value is in pounds, it will be converted to kilograms and vice versa */}
      <Typography variant='caption' className='pt-2 text-gray-700'>
        {convertUomValue(parseFloat(field.value.toString()))}
      </Typography>
    </Box>
  )
}
