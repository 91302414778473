import { Box } from "@mui/material"
import type { ReactElement } from "react"

import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator"
import { ComputedProductStatus, type Product } from "@/graphql/codegen/graphql"
import { ComputedProductStatusMap } from "@/screens/Companies/products/utils/productStatusMap"

interface ProductStatusCellProps {
  product: Product
  productId?: number | string
}

export const ProductStatusCell = ({ product, productId }: ProductStatusCellProps): ReactElement => (
  <Box className='flex items-center gap-x-4'>
    <StatusIndicator
      status={product.computedProductStatus ?? ComputedProductStatus.Inactive}
      map={ComputedProductStatusMap}
    />
    {productId ?? product.productId}
  </Box>
)
