import { Box, Chip, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material"
import { DescriptionOutlined, MoreVertOutlined, SettingsOutlined } from "@mui-symbols-material/w300"
import React, { useState } from "react"

import { useProductActionsStore } from "@/screens/Companies/products/stores/useProductsActions"
import { ProductActionsDialogs } from "@/screens/Companies/products/types/schema"

type ProductDetails = {
  id: string
  isActive?: boolean
  productDescription?: string
  vendorDescription?: string
  vendorSku?: string
  companyName?: string
  productManager?: string
  classification?: string
  category?: string
}

const ProductHeader: React.FC<ProductDetails> = (props: ProductDetails) => {
  const {
    id,
    productDescription,
    isActive,
    vendorDescription,
    vendorSku,
    companyName,
    productManager,
    classification,
    category,
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEditInformation = () => {
    setOpenedDialog(ProductActionsDialogs.EDIT_PRODUCT_INFORMATION)
    handleClose()
  }

  const handleEditSpecifics = () => {
    setOpenedDialog(ProductActionsDialogs.EDIT_PRODUCT_SPECIFICS)
    handleClose()
  }

  return (
    <Box className='rounded-md border border-solid border-gray-300 p-6'>
      <Box className='flex items-center justify-between gap-3'>
        <Box className='flex items-center gap-3'>
          <Typography variant='overline' className='tracking-[1px] text-gray-700'>
            AKROCHEM DESCRIPTION
          </Typography>
        </Box>
        <IconButton
          color='primary'
          aria-label='edit product options'
          onClick={handleClick}
          aria-controls={open ? "product-edit-menu" : undefined}
          aria-haspopup='true'
          aria-expanded={open ? "true" : undefined}
        >
          <MoreVertOutlined />
        </IconButton>
        <Menu
          id='product-edit-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "product-edit-button",
          }}
        >
          <MenuItem onClick={handleEditInformation}>
            <ListItemIcon>
              <DescriptionOutlined />
            </ListItemIcon>
            Edit Product Information
          </MenuItem>
          <MenuItem onClick={handleEditSpecifics}>
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            Edit Product Specifics
          </MenuItem>
        </Menu>
      </Box>
      <Box className='flex items-center gap-3'>
        <Typography variant='h5' className='text-gray-800'>
          {productDescription}
        </Typography>
        <Chip label={isActive ? "Active" : "Pending"} variant='outlined' color={isActive ? "success" : "warning"} />
        <Typography variant='body2' className='text-base font-medium text-gray-700'>
          Product ID:
          {id || "-----"}
        </Typography>
      </Box>
      <Box className='mt-6 flex items-center gap-6'>
        <Typography variant='body2' className='font-medium tracking-[0.46px] text-gray-700'>
          Vendor Description: {vendorDescription}
        </Typography>
        <Typography variant='body2' className='font-medium tracking-[0.46px] text-gray-700'>
          Vendor SKU: {vendorSku}
        </Typography>
        <Typography variant='body2' className='font-medium tracking-[0.46px] text-gray-700'>
          Vendor: {companyName}
        </Typography>
      </Box>
      <Box className='mt-6 flex items-center gap-6'>
        <Box className='flex items-center gap-2'>
          <Typography variant='body2' className='text-gray-700'>
            Category:
          </Typography>
          <Chip label={category} className='rounded-sm text-xs' />
        </Box>
        <Box className='flex items-center gap-2'>
          <Typography variant='body2' className='text-gray-700'>
            Classification:
          </Typography>
          <Chip label={classification} className='rounded-sm text-xs' />
        </Box>
        <Box className='flex items-center gap-2'>
          <Typography variant='body2' className='text-gray-700'>
            Product Manager:
          </Typography>
          {productManager ? <Chip label={productManager} className='rounded-sm text-xs' /> : "-"}
        </Box>
      </Box>
    </Box>
  )
}

export default ProductHeader
