import { z } from "zod"

import { LeadTimeUnits } from "@/graphql/codegen/graphql"

const emptyToNullableNumber = (value: any) => {
  const numberValue = Number(value)
  return isNaN(numberValue) || value === "" ? null : numberValue
}

const positiveNumberSchema = z.preprocess(
  emptyToNullableNumber,
  z.number().int().nonnegative({ message: "Value must be positive" }).nullable().optional()
)

export const ShippingDetailsSchema = z.object({
  weightPerTruckload: positiveNumberSchema,
  leadTimeInDays: positiveNumberSchema,
  minOrderQuantity: positiveNumberSchema,
  leadTimeDisplayUnits: z
    .nativeEnum(LeadTimeUnits, {
      errorMap: () => ({ message: "Must be either DAYS or WEEKS" }),
    })
    .default(LeadTimeUnits.Days),
})
