import { Box, IconButton } from "@mui/material"
import { EditOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"

import type { CompanyLocationAssociationOutput } from "@/graphql/codegen/graphql"

type AssociatedLocationsTableProps = {
  associatedLocationList: CompanyLocationAssociationOutput[]
  onEditClick: (associatedLocation: CompanyLocationAssociationOutput) => void
}

const AssociatedLocationsTable: FunctionComponent<AssociatedLocationsTableProps> = ({
  associatedLocationList,
  onEditClick,
}) => (
  <Box>
    {associatedLocationList.map((associatedLocation) => {
      const { companyLocationAssociationId: currentCompanyLocationAssociationId, companyDetail } = associatedLocation

      const locationAssociations = companyDetail?.company?.locationsAssociations
      const currentAssociation = locationAssociations?.find(
        ({ companyLocationAssociationId }) => companyLocationAssociationId === currentCompanyLocationAssociationId
      )
      const shipViaName = currentAssociation?.shippingVia?.name || ""
      const shippingAddress = currentAssociation?.shipping?.address1 || ""
      const billingAddress = currentAssociation?.billing?.address1 || ""
      const orderAddress = currentAssociation?.order?.address1 || ""
      const officeAddress = currentAssociation?.office?.address1 || ""
      if (!(shippingAddress && billingAddress)) return null
      return (
        <Box
          className='flex w-full space-x-8 border border-gray-300 px-6 py-4'
          key={currentCompanyLocationAssociationId}
        >
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Shipping</label>
            <span className='text-gray-900'>{shippingAddress}</span>
          </Box>
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Billing</label>
            <span className='text-gray-900'>{billingAddress}</span>
          </Box>
          {/* order */}
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Order</label>
            <span className='text-gray-900'>{orderAddress}</span>
          </Box>
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Office</label>
            <span className='text-gray-900'>{officeAddress}</span>
          </Box>
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Ship Via</label>
            <span className='text-gray-900'>{shipViaName}</span>
          </Box>
          <Box className='content-end'>
            <IconButton
              color='primary'
              aria-label='edit associated location details'
              onClick={() => onEditClick(associatedLocation)}
            >
              <EditOutlined />
            </IconButton>
          </Box>
        </Box>
      )
    })}
  </Box>
)

export default AssociatedLocationsTable
