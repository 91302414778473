import { ComputedProductStatus } from "@/graphql/codegen/graphql"
import { createStatusMap } from "@/utils/statusMap"

export const ComputedProductStatusMap = createStatusMap<
  ComputedProductStatus,
  "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning"
>([
  [ComputedProductStatus.Active, "success"],
  [ComputedProductStatus.Discontinued, "secondary"],
  [ComputedProductStatus.PendingApproval, "warning"],
  [ComputedProductStatus.Inactive, "error"],
])
