import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import type { UseFormReturn } from "react-hook-form"
import { useForm } from "react-hook-form"

import type { ProductUpdateFailure } from "@/graphql/codegen/graphql"
import { ProductFormInfoItem } from "@/graphql/codegen/graphql"
import type { AddProductModalType, AddProductModalUIType } from "@/screens/Products/components/AddProductSchema"
import { AddProductModalUISchema } from "@/screens/Products/components/AddProductSchema"
import type { LocationAssociation } from "@/screens/Products/components/ProductShipLocationSelect.tsx"
import { useGetProduct } from "@/screens/Products/hooks/useGetProduct.tsx"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseEditProductProps {
  productId: number
}

interface UseEditProductReturn {
  methods: UseFormReturn<AddProductModalUIType>
  handleSubmit: (data: AddProductModalType) => Promise<void>
  resetDone: boolean
}

export const useEditProduct = ({ productId }: UseEditProductProps): UseEditProductReturn => {
  const [resetDone, setResetDone] = useState(false)
  const { enqueueNotification } = useNotificationsStore()
  const { productDetails, productDetailsFetching, updateProduct } = useGetProduct(productId)

  const methods = useForm<AddProductModalUIType>({
    defaultValues: {
      companyId: "",
      vendorSku: "",
      vendorDescription: "",
      companyProductDescription: "",
      productManager: { id: "", name: "" },
      classification: { id: "", name: "" },
      productFormInfo: "",
      locationsAssociations: [],
      uomId: "",
      _hasExistingLocations: false,
    },
    resolver: zodResolver(AddProductModalUISchema),
    mode: "all",
  })

  const { setError } = methods

  useEffect(() => {
    if (productDetails) {
      const productManagerName = productDetails.productManager
        ? `${productDetails.productManager.firstName ?? ""} ${productDetails.productManager.lastName ?? ""}`.trim()
        : ""

      const formKey = Object.keys(ProductFormInfoItem).find(
        (key) => ProductFormInfoItem[key as keyof typeof ProductFormInfoItem] === productDetails.productFormInfo
      )

      const hasExistingLocations =
        productDetails.locationsAssociations && productDetails.locationsAssociations.length > 0

      const formData = {
        companyId: productDetails.company?.companyId ?? "",
        vendorSku: productDetails.vendorSku ?? "",
        vendorDescription: productDetails.vendorDescription ?? "",
        companyProductDescription: productDetails.companyProductDescription ?? "",
        productManager: {
          id: productDetails.productManagerId ?? "",
          name: productManagerName,
        },
        classification: {
          id: productDetails.classification?.id ?? "",
          name: productDetails.classification?.description ?? "",
        },
        productFormInfo: formKey ?? "",
        locationsAssociations:
          productDetails.locationsAssociations?.map((location) => ({
            address1: location.shipping?.address1 ?? "",
            city: location.shipping?.city ?? "",
            regionIsoCode: location.shipping?.regionIsoCode ?? "",
            locationAssociationId: location.locationAssociationId ?? "",
          })) || [],
        uomId: productDetails.uomId ?? "",
        _hasExistingLocations: hasExistingLocations,
      }

      methods.reset(formData)
      setResetDone(true)
    }
  }, [productDetailsFetching, productDetails, methods])

  const handleSubmit = async (formData: AddProductModalUIType) => {
    // Extract all UI-specific fields
    const { _hasExistingLocations, ...apiData } = formData
    const { productManager, locationsAssociations, classification, uomId, ...rest } = apiData

    // Prevent clearing locations if they were previously set
    if (_hasExistingLocations && (!locationsAssociations || locationsAssociations.length === 0)) {
      setError("locationsAssociations", {
        type: "custom",
        message:
          "Cannot remove all locations once they have been set. You can only update them to different locations.",
      })
      return
    }

    const locationsAssociationsArray =
      locationsAssociations.length > 0
        ? (locationsAssociations as LocationAssociation[]).map(({ locationAssociationId }) => ({
            locationAssociationId,
          }))
        : []

    const { error, data: result } = await updateProduct({
      input: {
        ...rest,
        uomId: uomId || null,
        classificationId: classification?.id ?? null,
        productId: Number(productId),
        productFormInfo: ProductFormInfoItem[apiData.productFormInfo as keyof typeof ProductFormInfoItem],
        locationsAssociations: locationsAssociationsArray,
        productManagerId: productManager.id,
      },
    })

    if (error || (result?.product?.update as ProductUpdateFailure)?.error) {
      const errorMessage = error?.message || (result?.product?.update as ProductUpdateFailure)?.error?.message
      setError("root", {
        type: "server",
        message: errorMessage,
      })
      // Set field-level error for better visibility
      setError("locationsAssociations", {
        type: "server",
        message: errorMessage,
      })
    } else {
      enqueueNotification({
        type: "success",
        content: <p>Product successfully updated</p>,
      })
    }
  }

  return { methods, handleSubmit, resetDone }
}
