import { LeadTimeUnits } from "@/graphql/codegen/graphql"

/**
 * This function is used to calculate the number of days based on the format (Days, Weeks, Months, Years) selected
 */
export const calculateInDays = (value: number, format: string): number => {
  switch (format) {
    case "Days":
      return value
    case "Weeks":
      return value * 7
    case "Months":
      return value * 30
    case "Years":
      return value * 365
    default:
      return value
  }
}

/**
 * Formats a lead time unit for display
 * @param unit The LeadTimeUnits enum value
 * @returns Properly cased unit label (e.g., "Days", "Weeks")
 */
export const formatLeadTimeUnit = (unit: LeadTimeUnits): string => {
  return unit === LeadTimeUnits.Days ? "Days" : "Weeks"
}

/**
 * Converts a lead time value to days based on the display unit of measure
 * @param value The lead time value to convert
 * @param uom The unit of measure (DAYS or WEEKS)
 * @returns The value converted to days (for storage)
 */
export const calculateLeadTimeInDays = (value: number, uom: LeadTimeUnits): number => {
  switch (uom) {
    case LeadTimeUnits.Weeks:
      return value * 7
    case LeadTimeUnits.Days:
    default:
      return value
  }
}

/**
 * Converts a lead time in days to the display value based on the unit of measure
 * @param leadTimeInDays The lead time value in days (as stored in database)
 * @param displayUom The display unit of measure (DAYS or WEEKS)
 * @returns The value converted to the display unit
 */
export const calculateLeadTimeDisplayValue = (
  leadTimeInDays: number | null | undefined,
  displayUom: LeadTimeUnits
): number | null => {
  if (!leadTimeInDays) return null

  if (displayUom === LeadTimeUnits.Weeks) {
    return Math.round(leadTimeInDays / 7)
  }
  return leadTimeInDays
}

/**
 * Formats a lead time value for display with proper units
 * @param leadTimeInDays The lead time value in days (as stored in database)
 * @param displayUom The display unit of measure (DAYS or WEEKS)
 * @returns Formatted string with value and proper unit (e.g., "14 Days", "02 Weeks")
 */
export const formatLeadTimeDisplay = (leadTimeInDays: number | null | undefined, displayUom: LeadTimeUnits): string => {
  const displayValue = calculateLeadTimeDisplayValue(leadTimeInDays, displayUom)
  if (!displayValue) {
    const displayUnit = formatLeadTimeUnit(displayUom)
    return `00 ${displayUnit}`
  }

  const formattedValue = displayValue < 10 ? `0${displayValue}` : `${displayValue}`
  const displayUnit = formatLeadTimeUnit(displayUom)
  const unit = displayValue === 1 ? displayUnit.slice(0, -1) : displayUnit
  return `${formattedValue} ${unit}`
}
