import { Box, Divider, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useCallback, useMemo } from "react"
import { FormProvider, type UseFormProps, useForm } from "react-hook-form"

import { getRefinedSearchParams } from "../utils"

import { StatusFilter } from "@/components/common/Filters/StatusFilter.tsx"
import type { ProductListFilter } from "@/graphql/codegen/graphql.ts"
import { ComputedProductStatus } from "@/graphql/codegen/graphql.ts"

const statusList: ComputedProductStatus[] = [
  ComputedProductStatus.Active,
  ComputedProductStatus.Discontinued,
  ComputedProductStatus.PendingApproval,
]

const statusKey = "statuses"

type FormValues = ProductListFilter & {
  status: ComputedProductStatus | null
}

const labelMap = {
  [ComputedProductStatus.Active]: "Active",
  [ComputedProductStatus.Discontinued]: "Discontinued",
  [ComputedProductStatus.PendingApproval]: "Pending Approval",
  [ComputedProductStatus.Inactive]: "Inactive",
}

export const ProductSearchFilter: FC = () => {
  const navigate = useNavigate({ from: "/companies/$companyId/edit" })
  const currentSearch = useSearch({
    from: "/companies/$companyId/edit",
    strict: true,
  })

  const { statuses } = currentSearch

  const defaultValues = useMemo(
    () => ({
      [statusKey]: statuses || null,
    }),
    [statuses]
  )

  const methods = useForm<FormValues>({
    defaultValues,
  } as UseFormProps<FormValues>)

  const handleFilterChange = useCallback(
    (name: keyof ProductListFilter, value: any) => {
      const params = getRefinedSearchParams(currentSearch, {
        ...currentSearch,
        [name]: value,
      })

      void navigate(params)
    },
    [currentSearch, navigate]
  )

  return (
    <FormProvider {...methods}>
      <Box className='flex h-full flex-col gap-y-2'>
        <Box className='flex-none'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            Product Status
          </Typography>
          <Divider />
          <StatusFilter
            name={statusKey}
            data={statusList}
            control={methods.control}
            onFilterChange={handleFilterChange}
            labelMap={labelMap}
          />
        </Box>
      </Box>
    </FormProvider>
  )
}
