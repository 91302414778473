import { type PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { useStore } from "zustand"
import { useShallow } from "zustand/shallow"

import type { PurchaseOrderState, createPurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

const PurchaseOrderStoreContext = createContext<ReturnType<typeof createPurchaseOrderStore> | null>(null)

export function PurchaseOrderProvider({
  initialStore,
  children,
}: PropsWithChildren<{ initialStore: ReturnType<typeof createPurchaseOrderStore> }>): JSX.Element {
  const [store, setStore] = useState(initialStore)

  // Update store when initialStore changes
  useEffect(() => {
    setStore(initialStore)
  }, [initialStore])

  return <PurchaseOrderStoreContext.Provider value={store}>{children}</PurchaseOrderStoreContext.Provider>
}

// Base hook for store access
const usePurchaseOrderStore = <T,>(selector: (state: PurchaseOrderState) => T): T => {
  const store = useContext(PurchaseOrderStoreContext)
  if (!store) {
    throw new Error("usePurchaseOrderStore must be used within PurchaseOrderProvider")
  }
  return useStore(store, selector)
}

// Atomic selectors
export const useCurrentPO = (): PurchaseOrderState["currentPO"] => usePurchaseOrderStore((state) => state.currentPO)

export const useFormValues = (): PurchaseOrderState["formValues"] => usePurchaseOrderStore((state) => state.formValues)

export const useModifiedFields = (): PurchaseOrderState["modifiedFields"] =>
  usePurchaseOrderStore(useShallow((state) => state.modifiedFields))

export const useOrderLines = (): PurchaseOrderState["formValues"]["ORDER_LINES"] =>
  usePurchaseOrderStore(useShallow((state) => state.formValues.ORDER_LINES))

export const useOrderLineDetails = (): PurchaseOrderState["formValues"]["ORDER_LINES_DETAILS"] =>
  usePurchaseOrderStore(useShallow((state) => state.formValues.ORDER_LINES_DETAILS))

export const useDeliveryTerms = (): PurchaseOrderState["formValues"]["DELIVERY_TERMS"] =>
  usePurchaseOrderStore(useShallow((state) => state.formValues.DELIVERY_TERMS))

export const useShippingLegs = (): PurchaseOrderState["formValues"]["SHIPPING_LEGS"] =>
  usePurchaseOrderStore(useShallow((state) => state.formValues.SHIPPING_LEGS))

// Actions
export const useUpdateSectionFields = (): PurchaseOrderState["updateSectionFields"] =>
  usePurchaseOrderStore((state) => state.updateSectionFields)

export const useUpdateModifiedFields = (): PurchaseOrderState["updateModifiedFields"] =>
  usePurchaseOrderStore((state) => state.updateModifiedFields)

export const useSetCurrentPO = (): PurchaseOrderState["setCurrentPO"] =>
  usePurchaseOrderStore((state) => state.setCurrentPO)

export const useIsManualOverridePrice = (productId: string): boolean =>
  usePurchaseOrderStore((state) => state.isManualOverridePriceMap[productId] ?? false)

export const useSetManualOverridePrice = (): PurchaseOrderState["setManualOverridePrice"] =>
  usePurchaseOrderStore((state) => state.setManualOverridePrice)

export const useResetAllModifiedFields = (): PurchaseOrderState["resetAllModifiedFields"] =>
  usePurchaseOrderStore((state) => state.resetAllModifiedFields)
