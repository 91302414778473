import { Box, Chip, Typography } from "@mui/material"
import type { CellContext, ColumnDef } from "@tanstack/react-table"

import { ActionsCell } from "./ActionsCell"
import { OrderQuantityColumn } from "./OrderQuantityColumn"
import { PriceUomColumn } from "./PriceUomColumn"
import { OrderQuantityColumnReadOnly } from "./read-only/OrderQuantityColumnReadOnly"
import { PriceUomColumnReadOnly } from "./read-only/PriceUomColumnReadOnly"

import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator"
import { LeadTimeUnits, type OrderLine } from "@/graphql/codegen/graphql"
import { ComputedProductStatus } from "@/graphql/codegen/graphql"
import { ComputedProductStatusMap } from "@/screens/Companies/products/utils/productStatusMap.ts"
import { formatLeadTimeDisplay } from "@/screens/Products/utils"

export const getPurchaseOrderProductTableColumns = (
  onOpenPriceDialog?: (product: OrderLine) => void,
  isReadOnly?: boolean,
  showCompanyName?: boolean
): ColumnDef<OrderLine>[] => {
  const columns: ColumnDef<OrderLine>[] = [
    {
      header: "Product ID",
      accessorKey: "productId",
      cell: ({ row }) => (
        <Box className='flex items-center gap-x-4'>
          <StatusIndicator
            status={row.original.productDetail?.product?.computedProductStatus ?? ComputedProductStatus.Discontinued}
            map={ComputedProductStatusMap}
          />
          {row.original.productId}
        </Box>
      ),
      size: 80,
    },
    ...(showCompanyName
      ? [
          {
            header: "Company Name",
            accessorKey: "companyName",
            cell: ({ row }: CellContext<OrderLine, unknown>) => (
              <Typography component='span'>{row.original.productDetail.product?.company?.name || "-"}</Typography>
            ),
          },
        ]
      : []),
    {
      header: "Description",
      cell: ({ row }) => (
        <Box className='gap-2'>
          <div className='text-wrap'>Akrochem: {row.original.productDetail?.product?.companyProductDescription}</div>
          <div className='text-wrap'>Supplier: {row.original.productDetail?.product?.vendorDescription}</div>
        </Box>
      ),
      size: 120,
    },
    {
      header: "Lead Time",
      accessorKey: "leadTimeInDays",
      cell: ({ row }) => {
        const displayUom = row.original.productDetail?.product?.leadTimeDisplayUnits || LeadTimeUnits.Days
        const leadTime = formatLeadTimeDisplay(row.original.productDetail?.product?.leadTimeInDays, displayUom)
        return <Chip label={leadTime} variant='outlined' className='h-[24px] rounded bg-primary-200 px-2' />
      },
      size: 80,
    },
    {
      header: "Order Quantity",
      cell: ({ row }) => (isReadOnly ? <OrderQuantityColumnReadOnly row={row} /> : <OrderQuantityColumn row={row} />),
      size: 200,
    },
    {
      header: "Price/UoM",
      accessorKey: "pallets",
      cell: ({ row }) =>
        isReadOnly ? (
          <PriceUomColumnReadOnly row={row} />
        ) : (
          <PriceUomColumn row={row} onOpenPriceDialog={onOpenPriceDialog} />
        ),
      size: 100,
    },
  ]

  if (!isReadOnly) {
    columns.push({
      header: "",
      accessorKey: "name",
      cell: ActionsCell,
      size: 50,
    })
  }

  return columns
}
