import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useEffect, useMemo } from "react"
import { useFormContext } from "react-hook-form"

import type { AddProductModalType } from "./AddProductSchema"
import { AddProductSpecifics } from "./AddProductSpecifics"

import { Button } from "@/components/common/Button"

type EditProductSpecificsModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: AddProductModalType) => Promise<void>
  resetDone: boolean
}

const EditProductSpecificsModal: FunctionComponent<EditProductSpecificsModalProps> = ({
  open,
  onClose,
  onSubmit,
  resetDone,
}) => {
  const {
    formState: { errors },
    reset,
    handleSubmit,
    trigger,
  } = useFormContext<AddProductModalType>()

  useEffect(() => {
    if (!resetDone) return
    trigger(["classification", "productFormInfo", "uomId"])
  }, [trigger, resetDone])

  const handleResetClick = useCallback(() => {
    reset()
    onClose("cancel")
  }, [reset, onClose])

  const handleOnSubmit = useCallback(
    async (formData: AddProductModalType) => {
      await onSubmit(formData)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  const isClassificationValid = useMemo(() => !errors.classification, [errors.classification])
  const isProductFormInfoValid = useMemo(() => !errors.productFormInfo, [errors.productFormInfo])
  const isUomIdValid = useMemo(() => !errors.uomId, [errors.uomId])

  const isFormValid = useMemo(() => {
    return isClassificationValid && isProductFormInfoValid && isUomIdValid
  }, [isClassificationValid, isProductFormInfoValid, isUomIdValid])

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        reset()
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10 font-normal text-primary'>Edit Product Specifics</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => {
          reset()
          onClose("cancel")
        }}
        className='absolute right-4 top-4 text-primary'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <AddProductSpecifics />
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        <Button disabled={!isFormValid} onClick={handleSubmit(handleOnSubmit)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditProductSpecificsModal
