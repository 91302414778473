import { useCallback, useEffect, useMemo, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type {
  Company,
  Location,
  PurchaseOrderCompanyLocationListQuery,
  PurchaseOrderCompanyLocationListQueryVariables,
} from "@/graphql/codegen/graphql"
import { CompanyStatus, PurchaseOrderCompanyLocationListDocument } from "@/graphql/codegen/graphql"
import type { LocationAssociation } from "@/screens/Products/components/ProductShipLocationSelect"
import { deduplicateLocations } from "@/utils/locations"

/**
 * Return type for the useGetPurchaseOrderIncotermsLocations hook
 */
type UseGetPurchaseOrderIncotermsLocationsReturn = {
  /** Array of locations available for incoterms */
  locations: Location[]
  /** Loading state for location data fetching */
  isLoading: boolean
  /** Error object if query fails */
  error: CombinedError | undefined
  /** Function to load more locations */
  loadMoreLocations: () => void
}

/** Number of records to fetch per page */
const PAGE_SIZE = 20

/**
 * Hook to fetch and manage incoterms locations for a purchase order
 *
 * This hook handles fetching both Akrochem locations and intermediary locations that can be used
 * as incoterms locations in a purchase order. It supports:
 * - Pagination through infinite scroll
 * - Deduplication of locations
 * - Sorting locations alphabetically
 * - Error handling
 * - Loading states
 *
 * The hook makes two separate queries:
 * 1. Akrochem locations query - fetches shipping locations from location associations
 * 2. Intermediary locations query - fetches direct location IDs
 *
 * Results are combined, deduplicated and sorted before being returned.
 *
 * @param purchaseOrderId - Optional purchase order ID. If not provided, queries will be paused
 * @returns Object containing:
 *  - locations: Combined array of deduplicated and sorted locations
 *  - isLoading: Boolean indicating if either query is in progress
 *  - error: Any error that occurred during fetching
 *  - loadMoreLocations: Function to trigger loading the next page of results
 *
 * @example
 * ```tsx
 * const {
 *   locations,
 *   isLoading,
 *   error,
 *   loadMoreLocations
 * } = useGetPurchaseOrderIncotermsLocations(purchaseOrderId);
 *
 * if (isLoading) return <Loading />;
 * if (error) return <Error message={error.message} />;
 *
 * return (
 *   <LocationList
 *     locations={locations}
 *     onLoadMore={loadMoreLocations}
 *   />
 * );
 * ```
 */
export const useGetPurchaseOrderIncotermsLocations = (
  shouldFetch = true
): UseGetPurchaseOrderIncotermsLocationsReturn => {
  const [currentPage, setCurrentPage] = useState(0)
  const [hasMorePages, setHasMorePages] = useState(true)

  // akrochem locations shipping locations from location associations; use locationId
  const [{ data: akrochemLocationData, fetching: isLoadingAkrochemLocations }, fetchMoreAkrochemLocations] = useQuery<
    PurchaseOrderCompanyLocationListQuery,
    PurchaseOrderCompanyLocationListQueryVariables
  >({
    query: PurchaseOrderCompanyLocationListDocument,
    variables: {
      input: {
        filter: {
          types: ["AKROCHEM", "AKROCHEM_WAREHOUSE", "CONSIGNMENT_WAREHOUSE", "CUSTOMER", "INTERMEDIARY_DESTINATION"],
          status: CompanyStatus.Active,
        },
        offset: currentPage * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
    },
    pause: !shouldFetch,
  })

  // intermediary locations get only locations id list; use locationId
  const [{ data: intermediaryLocationData, fetching: isLoadingIntermediaryLocations }] = useQuery<
    PurchaseOrderCompanyLocationListQuery,
    PurchaseOrderCompanyLocationListQueryVariables
  >({
    query: PurchaseOrderCompanyLocationListDocument,
    variables: {
      input: {
        filter: {
          types: ["INTERMEDIARY_DESTINATION"],
          status: CompanyStatus.Active,
        },
        offset: currentPage * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
    },
    pause: !shouldFetch,
  })

  const isLoading = useMemo(
    () => isLoadingAkrochemLocations || isLoadingIntermediaryLocations,
    [isLoadingAkrochemLocations, isLoadingIntermediaryLocations]
  )

  const parsedAkrochemLocations = useMemo(() => {
    if (!akrochemLocationData) return []
    if (akrochemLocationData?.company?.list?.__typename === "CompanyListFailure") {
      console.error(akrochemLocationData?.company?.list?.error?.message)
      return []
    }

    const companies = akrochemLocationData?.company?.list?.companies as Company[]
    const activeCompanies = companies.filter((company) => company.locations?.some((location) => location.isActive))
    const shippingLocations = activeCompanies
      .flatMap((company) =>
        company.locationsAssociations?.flatMap((locationAssociation) => locationAssociation.shipping)
      )
      .filter((location): location is LocationAssociation => Boolean(location?.locationId && location?.isActive))

    return shippingLocations
  }, [akrochemLocationData])

  const parsedIntermediaryLocations = useMemo(() => {
    if (!intermediaryLocationData) return []
    if (intermediaryLocationData?.company?.list?.__typename === "CompanyListFailure") {
      console.error(intermediaryLocationData?.company?.list?.error?.message)
      return []
    }

    const companies = intermediaryLocationData?.company?.list?.companies as Company[]
    const activeCompanies = companies.filter((company) => company.locations?.some((location) => location.isActive))
    const directLocations = activeCompanies
      .flatMap((company) => company.locations)
      .filter((location): location is Location => Boolean(location?.locationId && location?.isActive))

    return directLocations
  }, [intermediaryLocationData])

  const [combinedLocations, setCombinedLocations] = useState<(Location | LocationAssociation)[]>([])

  useEffect(() => {
    if (!isLoading) {
      const newLocations = [...parsedIntermediaryLocations, ...parsedAkrochemLocations]
      if (newLocations.length === 0) {
        setHasMorePages(false)
      }
      setCombinedLocations((prev) => {
        const mergedLocations = [...prev, ...newLocations]
        return deduplicateLocations(mergedLocations)
      })
    }
  }, [parsedIntermediaryLocations, parsedAkrochemLocations, isLoading])

  const sortedLocations = useMemo(() => {
    return combinedLocations.sort((a, b) => a.name.localeCompare(b.name))
  }, [combinedLocations])

  const loadMoreLocations = useCallback(() => {
    if (!shouldFetch || !hasMorePages) return
    setCurrentPage(currentPage + 1)
    fetchMoreAkrochemLocations({
      variables: { input: { offset: (currentPage + 1) * PAGE_SIZE, limit: PAGE_SIZE } },
    })
  }, [fetchMoreAkrochemLocations, currentPage, shouldFetch, hasMorePages])

  const error = useMemo(() => {
    if (!akrochemLocationData || !intermediaryLocationData) return undefined
    if (akrochemLocationData?.company?.list?.__typename === "CompanyListFailure") {
      return akrochemLocationData?.company?.list?.error
    }
  }, [akrochemLocationData, intermediaryLocationData])

  return {
    locations: sortedLocations,
    isLoading,
    error: error as CombinedError | undefined,
    loadMoreLocations,
  }
}
