// Unit of measurement ids for pound and kilogram
export const POUND_UOM_ID = "USSTD_WEIGHT_POUND"
export const KILOGRAM_UOM_ID = "METRIC_WEIGHT_KILOGRAM"
export const INCH_UOM_ID = "USSTD_DISTANCE_INCH"

// Multipliers for converting between pounds and kilograms
export const KILOGRAMS_TO_POUNDS = 2.20462
export const POUNDS_TO_KILOGRAMS = 1 / KILOGRAMS_TO_POUNDS

export const UOM_LABELS: Record<string, string> = {
  [POUND_UOM_ID]: "lb",
  [KILOGRAM_UOM_ID]: "kg",
}
