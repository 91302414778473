import { useMemo } from "react"
import type { CombinedError, OperationContext, OperationResult } from "urql"
import { useMutation, useQuery } from "urql"

import type { Mutation, Product, ProductGetSuccess, ProductMutationUpdateArgs, Query } from "@/graphql/codegen/graphql"
import { ProductSharedInformationDocument, UpdateProductMutationDocument } from "@/graphql/codegen/graphql"

export const useGetProduct = (
  productId: number
): {
  productDetails: Product
  updateProduct: (
    variables: ProductMutationUpdateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "product">, ProductMutationUpdateArgs>>
  productDetailsFetching: boolean
  productDetailsError: CombinedError | undefined
  reexecuteProductDetailsQuery: (opts?: Partial<OperationContext>) => void
} => {
  const [
    { data: productDetails, fetching: productDetailsFetching, error: productDetailsError },
    reexecuteProductDetailsQuery,
  ] = useQuery<Pick<Query, "product">>({
    query: ProductSharedInformationDocument,
    variables: { productId },
    pause: !productId,
  })

  const productDetailsData = useMemo(
    () => (productDetails?.product?.get as ProductGetSuccess)?.product,
    [productDetails]
  )

  // Mutation to update a product
  const [, updateProduct] = useMutation<Pick<Mutation, "product">, ProductMutationUpdateArgs>(
    UpdateProductMutationDocument
  )

  return {
    updateProduct,
    productDetails: productDetailsData,
    productDetailsFetching,
    productDetailsError,
    reexecuteProductDetailsQuery,
  }
}
