import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback } from "react"
import { useFormContext, useWatch } from "react-hook-form"

import type { AddProductModalType } from "./AddProductSchema"

import { Button } from "@/components/common/Button"
import { FormField } from "@/components/Forms"

type AddSKUModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: AddProductModalType) => Promise<void>
}

const AddSKUModal: FunctionComponent<AddSKUModalProps> = ({ open, onClose, onSubmit }) => {
  const { reset, handleSubmit, control } = useFormContext<AddProductModalType>()
  const vendorSku = useWatch({ control, name: "vendorSku" })

  const handleResetClick = useCallback(() => {
    reset()
    onClose("cancel")
  }, [reset, onClose])

  const handleOnSubmit = useCallback(
    async (formData: AddProductModalType) => {
      await onSubmit(formData)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        reset()
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10 font-normal text-primary'>Add SKU</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => {
          reset()
          onClose("cancel")
        }}
        className='absolute right-4 top-4 text-primary'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <FormField
          name='vendorSku'
          label='Vendor SKU'
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} fullWidth placeholder='Enter SKU' />
          )}
        />
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(handleOnSubmit)} disabled={!vendorSku}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddSKUModal
