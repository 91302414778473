import { Box } from "@mui/material"
import { type ReactElement, useMemo } from "react"

import type { Location } from "@/graphql/codegen/graphql"

export const useLocationOptions = (
  locations: Location[]
): { label: ReactElement; locationId: string; companyId: string }[] => {
  return useMemo(() => {
    return locations.map(({ address1, city, regionIsoCode, locationId, companyId }) => ({
      label: (
        <Box>
          <div>{address1}</div>
          <div>{`${city}, ${regionIsoCode}`}</div>
        </Box>
      ),
      locationId,
      companyId,
    }))
  }, [locations])
}
