import type { Row } from "@tanstack/react-table"
import { useCallback, useEffect, useMemo } from "react"

import {
  useCurrentPO,
  useIsManualOverridePrice,
  useOrderLineDetails,
  useOrderLines,
  useUpdateModifiedFields,
  useUpdateSectionFields,
} from "../../contexts/PurchaseOrderContext"
import { PurchaseOrderSection } from "../../types"

import { KILOGRAMS_TO_POUNDS, POUNDS_TO_KILOGRAMS, POUND_UOM_ID } from "@/constants/unitOfMeasurements"
import type { OrderLine, OrderUpdateInput } from "@/graphql/codegen/graphql"
import { PricingPackageSortField, SortingOrder } from "@/graphql/codegen/graphql"
import { useGetPricingPackage } from "@/screens/Products/hooks/useGetPricingPackage"
import { formatNumber } from "@/utils/formatNumber"
import { getVendorPrice } from "@/utils/getVendorPrice"

interface UsePriceUomColumn {
  currentPricing: string
  convertedUomValue: string
  total: string
  fetching: boolean
}

export const usePriceUomColumn = ({ row }: { row: Row<OrderLine> }): UsePriceUomColumn => {
  const orderLineDetails = useOrderLineDetails()
  const orderLines = useOrderLines()
  const updateModifiedFields = useUpdateModifiedFields()
  const updateSectionFields = useUpdateSectionFields()
  const order = useCurrentPO()

  // Safely get values with null checks
  const productId = row.original.productId
  const priceUomId = row.original.priceUomId
  const uomId = row.original.uomId
  const quantityInUom = row.original.quantityInUom ?? 0
  const pricePerUom = row.original.pricePerUom ?? 0
  const product = row.original.productDetail?.product

  const isManualOverride = useIsManualOverridePrice(productId.toString())

  const { pricingList, pricingListFetching } = useGetPricingPackage({
    filterSpecs: {
      companyId: product?.companyId ?? "",
      productId,
      sourceCompanyLocationAssociationId: product?.locationsAssociations?.[0]?.locationAssociationId ?? "",
    },
    sortSpecs: {
      field: PricingPackageSortField.EffectiveDate,
      order: SortingOrder.Asc,
    },
  })

  useEffect(() => {
    if ((pricingList.currentPricing || pricingList.futurePricing.length > 0) && pricingList.currentPricing) {
      const updatedOrderLineDetails = {
        ...orderLineDetails,
        [productId]: pricingList,
      }

      // Only update if the pricing has actually changed
      const currentPricing = orderLineDetails[productId]?.currentPricing
      if (JSON.stringify(currentPricing) !== JSON.stringify(pricingList.currentPricing)) {
        updateSectionFields(PurchaseOrderSection.ORDER_LINES_DETAILS, updatedOrderLineDetails)
      }
    }
  }, [pricingList.currentPricing, pricingList.futurePricing, orderLineDetails, productId, updateSectionFields])

  // Check if manual override price is applied
  const isManualOverridePrice =
    !!order?.orderLines?.find((line) => line.productId === productId)?.pricePerUom || isManualOverride || false

  // Memoized current price based on manual override or current pricing or 0 if not available
  const currentPrice = useMemo(() => {
    if (isManualOverridePrice) {
      return pricePerUom
    } else if (pricingList.currentPricing) {
      return getVendorPrice(pricingList.currentPricing, quantityInUom, uomId)
    } else {
      return 0
    }
  }, [quantityInUom, pricePerUom, pricingList.currentPricing, uomId, isManualOverridePrice])

  const handlePriceChange = useCallback(
    (newPrice: number) => {
      if (!newPrice) return

      const updatedOrderLines = orderLines.map((line) =>
        line.productId === productId
          ? {
              ...line,
              pricePerUom: newPrice,
            }
          : line
      )

      const originalLine = orderLines.find((line) => line.productId === productId)
      if (!originalLine || originalLine.pricePerUom === newPrice) return

      // Only update if the price has actually changed
      updateSectionFields(PurchaseOrderSection.ORDER_LINES, updatedOrderLines)
      updateModifiedFields(PurchaseOrderSection.ORDER_LINES, updatedOrderLines as Partial<OrderUpdateInput>)
    },
    [productId, orderLines, updateSectionFields, updateModifiedFields]
  )

  useEffect(() => {
    if (!currentPrice) return
    handlePriceChange(currentPrice)
  }, [currentPrice, handlePriceChange])

  // Determine the unit of measure (UOM) and conversion factor
  const pricingUom = !isManualOverridePrice ? pricingList.currentPricing?.unitOfMeasurementId : priceUomId
  const selectedUom = pricingUom === POUND_UOM_ID ? "lb" : "kg"

  // Memoized current pricing display (e.g., "$10.00 / lb")
  const currentPricing = useMemo(
    () => (currentPrice ? `$${formatNumber(currentPrice)} / ${selectedUom}` : "Not calculated"),
    [currentPrice, selectedUom]
  )

  // Convert price to alternate UOM (e.g., lb to kg or vice versa)
  const uomFactor = pricingUom === POUND_UOM_ID ? KILOGRAMS_TO_POUNDS : POUNDS_TO_KILOGRAMS
  const convertedValue: number = useMemo(
    () => (currentPrice ? parseFloat(currentPrice.toString()) * uomFactor : 0),
    [currentPrice, uomFactor]
  )

  // Memoized display for converted UOM price (e.g., "$5.00 / kg")
  const convertedUomValue = useMemo(
    () => (convertedValue ? `$${formatNumber(convertedValue)} / ${selectedUom === "lb" ? "kg" : "lb"}` : ""),
    [convertedValue, selectedUom]
  )

  // Calculate the total cost based on quantity and price in appropriate UOM
  const total = useMemo(
    () =>
      pricingUom === uomId
        ? formatNumber(Number(currentPrice) * quantityInUom, 2)
        : formatNumber(convertedValue * quantityInUom, 2),
    [currentPrice, quantityInUom, convertedValue, pricingUom, uomId]
  )

  return {
    currentPricing,
    convertedUomValue,
    total,
    fetching: pricingListFetching,
  }
}
