import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Typography } from "@mui/material"
import { type FC, useCallback } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { type ProductCreateInput, ProductStockType } from "@/graphql/codegen/graphql"
import { SelectComponent } from "@/screens/Products/components/AddProductSpecifics"

export const stockType = [
  {
    label: "Stock",
    value: ProductStockType.StockItem,
  },
  {
    label: "Non Stock",
    value: ProductStockType.NonStockItem,
  },
  {
    label: "Made to Order",
    value: ProductStockType.MadeToOrder,
  },
]

export const ProductConditionsContent: FC = () => {
  const { control, watch, setValue } = useFormContext<ProductCreateInput>()

  const handleChange = useCallback(
    (newValue: boolean, key: string) => {
      setValue(key, newValue)
    },
    [setValue]
  )

  return (
    <form>
      <Box>
        <Controller
          name='stockType'
          control={control}
          render={() => (
            <SelectComponent name='stockType' control={control} label='Stock' placeholder='Select One' hasClearButton>
              {stockType.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </SelectComponent>
          )}
        />
        <FormControl component='fieldset' variant='standard' className='mt-[27px]'>
          <Typography fontWeight='normal' className='mb-4 text-gray-800'>
            Select other relevant conditions for this product
          </Typography>
          <FormGroup>
            <Controller
              name='excludedFromCombinedPricing'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={watch("excludedFromCombinedPricing") ?? false}
                      onChange={(event) => handleChange(event.target.checked, "excludedFromCombinedPricing")}
                      name='excludedFromCombinedPricing'
                    />
                  }
                  className='mb-1'
                  label='Excluded From Combined Pricing'
                />
              )}
            />
            <Controller
              name='isOnAllocation'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={watch("isOnAllocation") ?? false}
                      onChange={(event) => handleChange(event.target.checked, "isOnAllocation")}
                      name='isOnAllocation'
                    />
                  }
                  className='mb-1'
                  label='On Allocation'
                />
              )}
            />
          </FormGroup>
        </FormControl>
      </Box>
    </form>
  )
}
