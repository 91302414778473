import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Typography } from "@mui/material"
import { FileUploadSection } from "components/upload/components/FileUploadSection"
import { type FC, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import type { ProductFormData, ProductFormDetailsDialogProps } from "./types"
import { UPLOAD_TYPES, schema } from "./types"

import { Button } from "@/components/common/Button"
import { FieldGroup, Form, FormField } from "@/components/Forms"
import type { FieldGroupEnum } from "@/graphql/codegen/graphql.ts"

const IMAGE_CONFIG = {
  accept: ".jpg,.png,.gif,.jpeg",
  maxSize: 10 * 1024 * 1024, // 10MB
  emptyStateText: "",
  dropStateText: "Drop image here",
}

export const ProductFormDetailsDialog: FC<ProductFormDetailsDialogProps> = ({
  open,
  formOptions,
  unitOfMeasurementOptions,
  onClose,
  onSubmit,
  initialData,
  previewFiles,
  onFileSelect,
  uploading,
}) => {
  const [isFileChanged, setIsFileChanged] = useState(false)
  const methods = useForm<ProductFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      productFormInfo: initialData?.productFormInfo ?? undefined,
      uomId: initialData?.uomId ?? "",
      signedUrls: initialData?.signedUrls || [],
    },
    mode: "onChange",
  })

  const {
    reset,
    formState: { isValid, isDirty },
    watch,
  } = methods

  const productFormInfo = watch("productFormInfo")
  const uomId = watch("uomId")

  const isFormValid = isValid && productFormInfo && uomId

  const handleSelectFile = (type: FieldGroupEnum, file: File) => {
    onFileSelect(type, file)
    setIsFileChanged(true)
  }

  useEffect(() => {
    if (open) {
      reset({
        productFormInfo: initialData?.productFormInfo ?? undefined,
        uomId: initialData?.uomId ?? "",
        signedUrls: initialData?.signedUrls ?? [],
      })
    } else {
      setIsFileChanged(false)
    }
  }, [open, initialData, reset, unitOfMeasurementOptions])

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Edit Product Form Details</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Form className='py-4'>
            <FieldGroup>
              {/* Product Form Info */}
              <FormField
                name='productFormInfo'
                label='Product Form'
                required
                render={({ field, fieldState: { error } }) => (
                  <Select {...field} fullWidth displayEmpty error={!!error} placeholder='Select Product Form'>
                    <MenuItem value={undefined} divider>
                      <Typography color='textSecondary'>Select Product Form</Typography>
                    </MenuItem>
                    {formOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {/* Unit of Measurement */}
              <FormField
                name='uomId'
                label='Unit of Measurement'
                required
                render={({ field, fieldState: { error } }) => (
                  <Select {...field} fullWidth displayEmpty error={!!error} placeholder='Select Unit of Measurement'>
                    <MenuItem value='' divider>
                      <Typography color='textSecondary'>Select Unit of Measurement</Typography>
                    </MenuItem>
                    {unitOfMeasurementOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {/* File Upload Section */}
              <Box className='flex justify-between'>
                {UPLOAD_TYPES.map(({ type, label }) => (
                  <FileUploadSection
                    key={type}
                    type={type}
                    label={label}
                    fileUrl={
                      previewFiles[type]?.previewUrl ||
                      initialData?.signedUrls?.find((url) => url.file?.fieldGroup === type)?.url ||
                      null
                    }
                    onFileSelect={handleSelectFile}
                    uploading={uploading}
                    {...IMAGE_CONFIG}
                  />
                ))}
              </Box>
            </FieldGroup>
          </Form>
        </FormProvider>
      </DialogContent>
      <DialogActions className='justify-between px-6 pb-4'>
        <Button onClick={onClose} appearance='outlined'>
          Cancel
        </Button>
        <Button
          onClick={methods.handleSubmit(onSubmit)}
          appearance='contained'
          disabled={!isFormValid || (!isDirty && !isFileChanged)}
        >
          {initialData ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
