import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material"
import {
  AddOutlined,
  EditOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import { type ReactElement, useCallback, useMemo, useState } from "react"
import { useMutation } from "urql"

import type { PackagingInfo } from "./PackagingInfo/usePackagingInfo"
import { usePackagingInfo } from "./PackagingInfo/usePackagingInfo"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import DetailList from "@/components/Tables/DetailList"
import { formattedPackagingTypes } from "@/constants/productPackagingType"
import type { Mutation, ProductMutationUpdateArgs, ProductUpdateFailure } from "@/graphql/codegen/graphql"
import { UpdateProductMutationDocument } from "@/graphql/codegen/graphql"
import { PackagingInfoModal } from "@/screens/Products/components/Product/Information/PackagingInfo/PackagingInfoModal"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

const useDetailListRows = (packagingInfo: PackagingInfo | null) => {
  const {
    freightItem,
    freightClass,
    packagingType,
    packageWeight,
    palletWeight,
    packageLength,
    packageWidth,
    packageHeight,
    palletSizeLength,
    palletSizeWidth,
    palletSizeHeight,
    packagesPerPallet,
  } = packagingInfo || {}

  const formattedPackagingType = useMemo(
    () => formattedPackagingTypes?.find(({ original }) => original === packagingType)?.formatted,
    [packagingType]
  )

  const listRows = useMemo(
    () => [
      { label: "Freight Item / Class", values: [freightItem ?? "-", freightClass ?? "-"] },
      {
        label: "Std. Packaging / Weight",
        values: [formattedPackagingType ?? "-", `${packageWeight ?? "-"}lb`],
      },
      {
        label: `Dimensions (in)
        ${formattedPackagingType ? " of the " + formattedPackagingType : ""}`,
        values: [`L ${packageLength ?? "-"}`, `W ${packageWidth ?? "-"}`, `H ${packageHeight ?? "-"}`],
      },
      {
        label: `Weight per Pallet (lb)`,
        values: [`${palletWeight ?? "-"}`],
      },
      {
        label: "Quantity per Pallet",
        values: [packagesPerPallet?.toString() ?? "-"],
      },
      {
        label: "Dimensions (in)",
        values: [`L ${palletSizeLength ?? "-"}`, `W ${palletSizeWidth ?? "-"}`, `H ${palletSizeHeight ?? "-"}`],
      },
    ],
    [
      formattedPackagingType,
      freightClass,
      freightItem,
      packageHeight,
      packageLength,
      packageWeight,
      packageWidth,
      packagesPerPallet,
      palletSizeHeight,
      palletSizeLength,
      palletSizeWidth,
      palletWeight,
    ]
  )

  return listRows
}

const useHasPackagingInformation = (packagingInfo: PackagingInfo | null) => {
  const falsyValues = useMemo(() => [0, "", null, undefined], [])

  const hasPackagingInformation = useMemo(
    () =>
      Object.entries(packagingInfo ?? {}).some(([key, value]) => key !== "__typename" && !falsyValues.includes(value)),
    [falsyValues, packagingInfo]
  )

  return hasPackagingInformation
}

export const ProductPackageInformationContainer = (): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [expanded, setExpanded] = useState(true)
  const { enqueueNotification } = useNotificationsStore()

  const { productId: productIdParam } = useParams({
    from: "/product/$productId/edit",
  })

  const productId = Number(productIdParam)

  const {
    data: packagingInfo,
    fetch: fetchPackagingInfo,
    loading,
  } = usePackagingInfo({
    productId,
  })

  const [, updateProduct] = useMutation<Pick<Mutation, "product">, ProductMutationUpdateArgs>(
    UpdateProductMutationDocument
  )

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async (data: any) => {
      const { error, data: result } = await updateProduct({
        input: {
          ...data,
          productId,
        },
      })

      if (error || (result?.product.update as ProductUpdateFailure)?.error) {
        enqueueNotification({
          type: "error",
          content: <p>Failed to update the product packaging information</p>,
        })
      } else {
        enqueueNotification({
          type: "success",
          content: <p>Product packaging information successfully updated.</p>,
        })

        handleModalClose()

        fetchPackagingInfo({ productId })
      }
    },
    [enqueueNotification, fetchPackagingInfo, handleModalClose, productId, updateProduct]
  )

  const showPackagingInformation = useHasPackagingInformation(packagingInfo)

  const detailListRows = useDetailListRows(packagingInfo)

  return (
    <Paper className='flex flex-1 flex-col border border-gray-300 p-6' elevation={0}>
      <PackagingInfoModal
        open={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleSubmit}
        defaultValues={packagingInfo || {}}
        loading={loading}
      />
      <Box className='flex items-start justify-between'>
        <Typography variant='h6'>Packaging Information</Typography>
        <Box className={expanded ? "mb-6" : ""}>
          {showPackagingInformation && expanded && (
            <IconButton color='primary' aria-label='edit product packaging information' onClick={handleOpenModal}>
              <EditOutlined />
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label='show more'>
            {expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded}>
        {showPackagingInformation ? (
          <DetailList rows={detailListRows} className='mr-10' />
        ) : (
          <InformationMissingAlert
            icon={<WarningOutlined color='primary' />}
            title='Add Packaging Information'
            description={""}
          >
            <Button
              color='inherit'
              size='small'
              appearance='outlined'
              className='text-primary-500 self-start'
              startIcon={<AddOutlined />}
              onClick={handleOpenModal}
            >
              Packaging Info
            </Button>
          </InformationMissingAlert>
        )}
      </Collapse>
    </Paper>
  )
}
