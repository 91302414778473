import { Box, ListItemText, MenuItem } from "@mui/material"
import type { FunctionComponent } from "react"
import React from "react"
import { useFormContext } from "react-hook-form"

import { Button } from "@/components/common/Button"
import type { Company, Location } from "@/graphql/codegen/graphql"
import FormAutocomplete from "@/screens/Companies/components/FormAutocomplete.tsx"
import LocationSelect from "@/screens/Companies/create/components/LocationSelect.tsx"
import { useLocationOptions } from "@/screens/Companies/hooks/useLocationOptions.tsx"

type AssociatedLocationProps = {
  locations: Location[]
  companyNameList: Company[]
  onSubmit: () => void
  handleScrollLocations: (event: React.UIEvent<HTMLUListElement>) => void
  isBillingFieldDisabled: boolean
  setSearchTerm: (value: string) => void
  loadMoreCompanies: () => void
}

const AssociatedLocation: FunctionComponent<AssociatedLocationProps> = ({
  locations,
  handleScrollLocations,
  companyNameList,
  onSubmit,
  isBillingFieldDisabled,
  setSearchTerm,
  loadMoreCompanies,
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()
  const shippingValue = watch("shipping")
  const billingValue = watch("billing")
  const orderValue = watch("order")

  const genericOptionList = useLocationOptions(locations)

  const isAssociateEnabled = shippingValue && billingValue && orderValue

  return (
    <form onSubmit={onSubmit} className='mb-10 flex w-full space-x-8'>
      <LocationSelect
        control={control}
        name='shipping'
        label='Shipping'
        renderLabel='address1'
        required
        options={genericOptionList}
        handleScroll={handleScrollLocations}
        locations={locations}
        errorMessage={errors?.shipping?.message}
      />
      <LocationSelect
        control={control}
        name='billing'
        label='Billing'
        renderLabel='address1'
        required
        disabled={isBillingFieldDisabled}
        handleScroll={handleScrollLocations}
        options={genericOptionList}
        locations={locations}
        errorMessage={errors.billing?.message}
      />
      <LocationSelect
        control={control}
        name='order'
        label='Order'
        renderLabel='address1'
        required
        handleScroll={handleScrollLocations}
        options={genericOptionList}
        locations={locations}
        errorMessage={errors.order?.message}
      />
      <LocationSelect
        control={control}
        name='office'
        label='Office'
        renderLabel='address1'
        hasClearButton
        handleScroll={handleScrollLocations}
        locations={locations}
        options={genericOptionList}
      />
      <FormAutocomplete
        control={control}
        name='shipVia'
        label='Ship Via'
        options={companyNameList}
        valueKey='companyId'
        labelKey='name'
        setSearchTerm={setSearchTerm}
        onLoadMore={loadMoreCompanies}
        renderOption={(props, option) => (
          <MenuItem {...props} divider key={`${option.label}-${option.externalSystemId}`}>
            <ListItemText primary={option.name} secondary={option.externalSystemId} />
          </MenuItem>
        )}
      />
      <Box className='content-end'>
        <Button appearance='contained' type='submit' disabled={!isAssociateEnabled}>
          Associate
        </Button>
      </Box>
    </form>
  )
}

export default AssociatedLocation
