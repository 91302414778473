import { useNavigate, useSearch } from "@tanstack/react-router"
import type { OnChangeFn, Row, SortingState } from "@tanstack/react-table"
import { getCoreRowModel } from "@tanstack/react-table"
import type { ReactElement } from "react"
import { useCallback, useEffect, useMemo, useRef } from "react"

import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import type { ContactSortField } from "@/graphql/codegen/graphql"
import { SortingOrder } from "@/graphql/codegen/graphql"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import {
  accessorKeyToContactSortField,
  columns,
  contactSortFieldToAccessoryKey,
} from "@/screens/Companies/components/tables/ContactsTableUtils"
import type { ContactSearchResult, SortableAccessorKey } from "@/screens/Companies/components/tables/ContactsTableUtils"
import type { ContactSearchFilters } from "@/screens/Companies/stores/useContactSearchStore.ts"
import { useContactSearchStore } from "@/screens/Companies/stores/useContactSearchStore.ts"
import { SearchCategory } from "@/screens/Companies/types/enums"
import useDebounce from "@/utils/useDebounce"

const isRow = (value: unknown): value is Row<ContactSearchResult> => {
  return value !== null && typeof value === "object" && "original" in value
}

export const ContactsTable = (): ReactElement => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/companies/" }) as ContactSearchFilters
  const { query, field, order } = currentSearch

  const data = useContactSearchStore(({ data }) => data) as ContactSearchResult[]
  const fetching = useContactSearchStore(({ fetching }) => fetching)
  const error = useContactSearchStore(({ error }) => error)
  const executeSearch = useContactSearchStore(({ executeSearch }) => executeSearch)
  const loadMore = useContactSearchStore(({ loadMore }) => loadMore)
  const clearStore = useContactSearchStore(({ clearStore }) => clearStore)

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const debouncedExecuteSearch = useDebounce((filters: ContactSearchFilters) => {
    executeSearch(queryClient, filters)
  }, 300)

  const handleLoadMore = useCallback(() => {
    if (currentSearch && !fetching) {
      loadMore(queryClient, currentSearch)
    }
  }, [currentSearch, fetching, loadMore])

  const sorting = useMemo(() => {
    const tableSortField = contactSortFieldToAccessoryKey.get(field as ContactSortField) as SortableAccessorKey
    const tableSortOrder = order || SortingOrder.Asc
    return [
      {
        id: tableSortField,
        asc: tableSortOrder === SortingOrder.Asc,
        desc: tableSortOrder === SortingOrder.Desc,
      },
    ]
  }, [field, order])

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const newSorting = typeof updaterOrValue === "function" ? updaterOrValue(sorting) : updaterOrValue
      if (newSorting.length > 0) {
        const sort = newSorting[0]
        const key = sort.id as SortableAccessorKey
        const field = accessorKeyToContactSortField.get(key)
        void navigate({
          search: (prev) => ({
            ...prev,
            query,
            category: SearchCategory.CONTACT,
            field: field ?? undefined,
            order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
          }),
          to: "/companies",
          replace: true,
        })
      }
    },
    [query, sorting, navigate]
  )

  const handleRowClick = useCallback(
    (value: unknown) => {
      if (isRow(value)) {
        navigate({ to: `/contact/${value.original.contactId}` })
      }
    },
    [navigate]
  )

  const onScroll = useScrollLoadMore({
    fetching,
    loadMore: handleLoadMore,
    scrollContainerRef: tableContainerRef,
  })

  // Execute search when filters change
  useEffect(() => {
    debouncedExecuteSearch(currentSearch)
  }, [debouncedExecuteSearch, currentSearch])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearStore()
    }
  }, [clearStore])

  return (
    <AerosBaseTable<ContactSearchResult>
      error={error}
      features={{
        table: {
          stickyHeader: true,
        },
        tanstackOptions: {
          data,
          columns,
          state: {
            sorting,
            isLoading: fetching,
          },
          onSortingChange: handleSortingChange,
          getCoreRowModel: getCoreRowModel(),
          manualSorting: true,
          enableSortingRemoval: false,
        },
      }}
      slotProps={{
        container: {
          ref: tableContainerRef,
          onScroll,
          sx: { height: "inherit", position: "relative" },
        },
        bodyRow: {
          className: "hover:bg-primary-100 cursor-pointer",
          onClick: handleRowClick,
        },
      }}
      noDataMessage='No contacts found'
    />
  )
}
