import { zodResolver } from "@hookform/resolvers/zod"
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { useCallback, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { LocationNotesForm } from "@/screens/Companies/edit/components/locations/LocationNotesForm"
import {
  type AddNotesDialogProps,
  type AddNotesQueryProps,
  AddNotesQuerySchema,
} from "@/screens/Companies/types/dialogProps"

export const AddNotesDialog: FC<AddNotesDialogProps> = ({ open, onClose, onSubmit }) => {
  const methods = useForm<AddNotesQueryProps>({
    values: { notes: "" },
    resolver: zodResolver(AddNotesQuerySchema),
    mode: "onBlur",
  })

  const handleSave = useCallback(
    async (value: AddNotesQueryProps) => {
      const isValid = await methods.trigger()
      if (isValid) {
        onSubmit(value)
        onClose({ data: methods.getValues(), reason: "success" })
      }
    },
    [onSubmit, onClose, methods]
  )

  useEffect(() => {
    if (open) methods.reset()
  }, [methods, open])

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={(_, reason) => onClose({ data: methods.getValues(), reason })} fullWidth>
        <DialogTitle className='pl-10'>
          <span className='font-normal text-primary'>Add Location Notes</span>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={() => onClose({ data: methods.getValues(), reason: "cancel" })}
          className='absolute right-4 top-4 text-primary'
        >
          <CloseOutlined />
        </IconButton>
        <Divider />
        <DialogContent classes={{ root: "p-10" }}>
          <LocationNotesForm />
        </DialogContent>
        <DialogActions className='justify-between px-10 pb-10'>
          <Button
            variant='primary'
            appearance='outlined'
            onClick={() => onClose({ data: methods.getValues(), reason: "cancel" })}
          >
            Cancel
          </Button>
          <Button variant='primary' onClick={methods.handleSubmit(handleSave)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}
