import { Box, MenuItem, Select, Tooltip } from "@mui/material"
import { InfoOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import React from "react"
import type { Control } from "react-hook-form"
import { Controller } from "react-hook-form"

import SelectIconWithClear from "@/components/SelectIconWithClearProps/SelectIconWithClear"
import type { Location } from "@/graphql/codegen/graphql.ts"

type LocationOption = {
  label: React.ReactNode
  locationId: string
  companyId?: string
}

type LocationSelectProps = {
  control: Control<any>
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  errorMessage?: any
  handleScroll?: (event: React.UIEvent<HTMLUListElement>) => void
  options: LocationOption[]
  hasClearButton?: boolean
  showTooltip?: boolean
  tooltipText?: string
  renderLabel?: string
  locations: Location[]
}

const LocationSelect: React.FC<LocationSelectProps> = ({
  control,
  name,
  label,
  required,
  disabled,
  errorMessage,
  handleScroll,
  options,
  hasClearButton = false,
  showTooltip = false,
  tooltipText = "",
  locations,
}) => {
  return (
    <Box className='w-full'>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {label && (
              <label className='mb-2 block text-sm font-normal text-gray-700'>
                {label}
                {required && <span className='text-red-600'>*</span>}
              </label>
            )}
            <Select
              {...field}
              fullWidth
              disabled={disabled}
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: 300 },
                  onScroll: handleScroll,
                },
              }}
              IconComponent={
                hasClearButton
                  ? ({ className }) => (
                      <SelectIconWithClear
                        value={field.value}
                        onClear={() => field.onChange("")}
                        className={className}
                      />
                    )
                  : KeyboardArrowDownOutlined
              }
              endAdornment={
                showTooltip ? (
                  <Tooltip title={tooltipText}>
                    <InfoOutlined className='absolute right-10 text-gray-600' />
                  </Tooltip>
                ) : null
              }
              className='h-[42px] border-none hover:border-gray-400'
              sx={{
                "& .MuiSelect-select": {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                },
              }}
              renderValue={(selected: string) => {
                return locations.find(({ locationId }) => locationId === selected)?.address1
              }}
            >
              {options.map(({ label: itemLabel, locationId }) => (
                <MenuItem key={locationId} value={locationId} divider>
                  {itemLabel}
                </MenuItem>
              ))}
            </Select>
            {errorMessage && <span className='text-sm text-red-600'>{errorMessage}</span>}
          </>
        )}
      />
    </Box>
  )
}

export default LocationSelect
