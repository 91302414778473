import type { FunctionComponent } from "react"
import type { UseFormReturn } from "react-hook-form"

import AddLocationModal from "./AddLocationModal"
import AddSKUModal from "./AddSKUModal"
import EditProductInformationModal from "./EditProductInformationModal"
import EditProductSpecificsModal from "./EditProductSpecificsModal"

import { useProductActionsStore } from "@/screens/Companies/products/stores/useProductsActions"
import { ProductActionsDialogs } from "@/screens/Companies/products/types/schema"
import type { AddProductModalType } from "@/screens/Products/components/AddProductSchema"

type EditProductModalContainerProps = {
  methods: UseFormReturn<AddProductModalType>
  resetDone: boolean
  onSubmit: (data: AddProductModalType) => Promise<void>
}

const EditProductModalContainer: FunctionComponent<EditProductModalContainerProps> = ({
  methods,
  resetDone,
  onSubmit,
}) => {
  const { openedDialog, setOpenedDialog } = useProductActionsStore()

  const handleSubmit = async (data: AddProductModalType) => {
    await onSubmit(data)
    setOpenedDialog(null)
  }

  const handleClose = () => {
    methods.reset()
    setOpenedDialog(null)
  }

  return (
    <>
      <EditProductInformationModal
        open={openedDialog === ProductActionsDialogs.EDIT_PRODUCT_INFORMATION}
        onClose={handleClose}
        onSubmit={handleSubmit}
        resetDone={resetDone}
      />
      <EditProductSpecificsModal
        open={openedDialog === ProductActionsDialogs.EDIT_PRODUCT_SPECIFICS}
        onClose={handleClose}
        onSubmit={handleSubmit}
        resetDone={resetDone}
      />
      <AddSKUModal
        open={openedDialog === ProductActionsDialogs.ADD_SKU}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
      <AddLocationModal
        open={openedDialog === ProductActionsDialogs.ADD_LOCATION}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default EditProductModalContainer
